import { SurveyCard } from "../../surveycard/surveycard";
import { Title } from "../../typography/title";
import { DashboardButton } from "../../dashboard-button/dashboard-button";
import { Survey } from "../../../redux/survey/survey.types";
import { SurveyDisplayStatus } from "../../../redux/survey/survey.types";


export interface SurveyGridProps {
  cards: Survey[];
  title: string;
  displayStatus:SurveyDisplayStatus;
  isEmpty: boolean;
  currentPage: number;
}

export const SurveyGrid = ({cards, title, displayStatus, isEmpty, currentPage }: SurveyGridProps) =>{
  const emptyString = `You have no ${displayStatus} surveys.`;

  function renderEmptyState() {
    if (isEmpty && displayStatus === SurveyDisplayStatus.Live) {
      return (
        <div className='my-20'>
          <Title text={'Create your first survey with Invisibly today!'} />
        </div>
      );
    }

    return (
      <div className='my-20'>
        <p className="text-center text-gray-500">
          {emptyString}
        </p>
      </div>
    );
  }

  function renderList() {
    return (
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
        {cards.map((card, index) => (
          <SurveyCard
            key={index}
            {...card}
            currentPage={currentPage}
          />
        ))}
      </div>
    );
  }

  return (
    <div className='mb-4 w-full'>
      <div className="relative mb-5 flex sm-max:justify-between items-center">
        <Title
          text={title}
          className="mr-8 sm-max:mr-0 text-[2rem] micro:text-2xl"
        />
        {displayStatus === SurveyDisplayStatus.Live && (
          <DashboardButton />
        )}
      </div>
      {cards.length > 0 ? renderList() : renderEmptyState()}
    </div>
  );
};
