import { Button } from 'primereact/button';
import './b2bbutton.css';
import 'primeicons/primeicons.css';
import { FadeLoader } from 'react-spinners';

type Theme = 'dark' | 'light' | 'blue' | 'disabled';

interface B2BButtonProps {
  expand?: boolean;
  outline?: boolean;
  theme?: Theme;
  imgSrc?: string;
  type?: string;
  icon?: string;
  isThin?: boolean;
  iconPosition?: 'left' | 'center';
  label: string;
  disable?: boolean;
  onClick?: (arg?: any) => void;
  isLoading?: boolean;
  size?: 'small' | 'regular' | 'regular-thin';
}

const computeTheme = (theme: Theme) => {
  switch (theme) {
    case 'light':
      return 'bg-white text-black';
    case 'dark':
      return 'bg-black text-white';
    case 'blue':
      return 'bg-custom-blue-2 text-white';
    case 'disabled':
      return 'bg-custom-gray-disabled text-white invert';
    default:
      return 'bg-white text-black';
  }
};

export const B2BButton = ({
  expand = false,
  outline = false,
  icon,
  imgSrc,
  theme = 'light',
  type = 'button',
  label,
  disable = false,
  isLoading = false,
  size = 'regular',
  iconPosition = 'left',
  onClick = () => {},
  ...props
}: B2BButtonProps) => {
  const isExpanded = expand ? 'w-full' : '';
  const computedTheme = computeTheme(disable ? 'disabled' : theme);
  const outlined = outline || theme === 'light' ? 'outline outline-gray-300 outline-1' : '';
  const sizeClass = size === 'small' ? 'small' : size === 'regular-thin' ? 'regular-thin' : 'regular';
  const withIcon = iconPosition === 'left' ? '' : 'flex justify-center items-center';
  const renderIcon = () => {
    if (!isLoading && icon) {
      return (
        <img
          src={icon}
          className={`${iconPosition === 'left' ? 'ml-2' : 'mx-2'}`}
          draggable={false}
        />
      );
    }
  };

  return (
    <Button
      disabled={disable}
      type={type as 'button' | 'submit' | 'reset' | undefined}
      rounded
      className={`b2b-btn justify-center ${outlined} ${computedTheme} ${sizeClass} ${isExpanded}`}
      {...props}
      onClick={() => !isLoading && onClick()}
    >
      {isLoading ? (
        <div className="mt-4 pl-6 flex items-center justify-center w-full">
          <FadeLoader
            color="white"
            width={3}
            height={8}
            margin={-8}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          {iconPosition === 'left' && renderIcon()}
          {imgSrc && (
            <img
              src={imgSrc}
              alt="button icon"
              className="ml-2 size-6"
              draggable={false}
            />
          )}
          <span className={`flex-1 text-center ${withIcon} mx-4`}>
            {iconPosition === 'center' && renderIcon()}
            {label}
          </span>
        </div>
      )}
    </Button>
  );
};
