import { useState, useEffect } from "react";
import { SurveySection } from "../../components/survey-section/survey-section";
import { DashboardMenubar } from "../../components/menubar/menubar";
import { useGetSurveysQuery } from "../../redux/survey/survey.endpoints";
import { SurveyStatus, SurveyDisplayStatus } from "../../redux/survey/survey.types";
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { SURVEY_GRID_PAGE_SIZE } from "../../services/surveys/survey.constants";
import dashEmptyGraphic from '../../assets/images/dash-empty-graphic.png';
import {DashboardButton} from "../../components/dashboard-button/dashboard-button";
import {usePaymentLandingListener} from "./usePaymentLanding";
import {useSetPageTitle} from "../../hooks/useSetPageTitle";
import FirebaseService from "../../services/firebase/firebase.service";
import {DashboardEmptyStateCopy} from "./dashboard.types";

const DashboardPage: React.FC = () => {
  const { emptyStateTitle, emptyStateSubtitle, learnMoreHelperText, learnMoreLink } = FirebaseService.getRemoteConfigJSON<DashboardEmptyStateCopy>('DASHBOARD_EMPTY_STATE_COPY');
  useSetPageTitle('Dashboard');
  usePaymentLandingListener();
  const [activePage, setActivePage] = useState(1);
  const [inProgressPage, setInProgressPage] = useState(1);
  const [completePage, setCompletePage] = useState(1);
  const [pageSize, setPageSize] = useState(SURVEY_GRID_PAGE_SIZE);
  const [loading, setLoading] = useState(true);

  const fetchSurveysByStatus = (page: any, pageSize: any, surveyStatuses: any[]) => {
    const { data, isLoading } = useGetSurveysQuery({ page, page_size: pageSize, survey_status: surveyStatuses.join(',') }, { refetchOnMountOrArgChange: true });
    const surveys = data?.surveys || [];
    const totalObjects = data?.totalObjects || 0;
    return { surveys, totalObjects, isLoading };
  };

  const { surveys: activeSurveys, totalObjects: totalActiveObjects, isLoading: activeLoading } = fetchSurveysByStatus(activePage, pageSize, [SurveyStatus.Live]);
  const { surveys: inProgressSurveys, totalObjects: totalInProgressObjects, isLoading: inProgressLoading } = fetchSurveysByStatus(inProgressPage, pageSize, [SurveyStatus.Draft, SurveyStatus.Review, SurveyStatus.Rejected]);
  const { surveys: completeSurveys, totalObjects: totalCompleteObjects, isLoading: completeLoading } = fetchSurveysByStatus(completePage, pageSize, [SurveyStatus.Complete]);
  useEffect(() => {
    if (!activeLoading) {
      setLoading(false);
    }
  }, [activeLoading]);

  const handlePageChange = (status: SurveyDisplayStatus, event: PaginatorPageChangeEvent) => {
    const nextPage = Math.ceil((event.first / pageSize) + 1);
    if (status === SurveyDisplayStatus.Live) {
      setActivePage(nextPage);
    } else if (status === SurveyDisplayStatus.Draft) {
      setInProgressPage(nextPage);
    } else if (status === SurveyDisplayStatus.Complete) {
      setCompletePage(nextPage);
    }
  };

  const isEmpty = !loading && activeSurveys.length === 0 && inProgressSurveys.length === 0 && completeSurveys.length === 0;
  return (
    <div className="flex min-h-screen w-full flex-col">
      <DashboardMenubar />
      {loading ? (
        <div className="flex justify-center items-center w-full h-screen" />
      ) : isEmpty ? (
        <div className="mx-auto mb-80 w-full md:w-1/2 flex grow flex-col items-center justify-center p-4 text-center">
          <img
            src={dashEmptyGraphic}
            alt=""
            className="rounded-full"
            draggable={false}
          />
          <p className="mt-4 text-2xl font-thin">
            {emptyStateTitle}
          </p>
          <p className="mb-4 text-lg font-thin">
            {emptyStateSubtitle}
          </p>
          <div className="text-sm mb-4 text-gray-400">
            <a
              href={learnMoreLink}
              className="text-gray-400 underline mr-1"
            >
              Learn more
            </a>
            {learnMoreHelperText}
          </div>
          <DashboardButton />
        </div>
      ) : (
        <div className="mx-4 my-14 md:mx-24 lg:mx-24">
          <SurveySection
            title="Live surveys"
            surveys={activeSurveys}
            totalRecords={totalActiveObjects}
            displayStatus={SurveyDisplayStatus.Live}
            currentPage={activePage}
            pageSize={pageSize}
            onPageChange={(event) => handlePageChange(SurveyDisplayStatus.Live, event)}
            isEmpty={isEmpty}
          />
          <SurveySection
            title="Draft surveys"
            surveys={inProgressSurveys}
            totalRecords={totalInProgressObjects}
            displayStatus={SurveyDisplayStatus.Draft}
            currentPage={inProgressPage}
            pageSize={pageSize}
            onPageChange={(event) => handlePageChange(SurveyDisplayStatus.Draft, event)}
            isEmpty={isEmpty}
          />
          <SurveySection
            title="Completed surveys"
            surveys={completeSurveys}
            totalRecords={totalCompleteObjects}
            displayStatus={SurveyDisplayStatus.Complete}
            currentPage={completePage}
            pageSize={pageSize}
            onPageChange={(event) => handlePageChange(SurveyDisplayStatus.Complete, event)}
            isEmpty={isEmpty}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
