import React, {useEffect, useRef, useState} from 'react';
import chevron from '../../../assets/icons/chevron.svg';
import { Attribute, attributes } from '../criteria-settings';
import CriteriaListbox from "./criteria-listbox.component";

interface NestedDropdownProps {
  filter?: boolean;
  onClose: () => void;
  onCreateAttribute: (attribute: Attribute | null) => void;
}
const NestedDropdown: React.FC<NestedDropdownProps> = ({ filter = true, onClose, onCreateAttribute }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOuterClick);
    return () => {
      document.removeEventListener('mousedown', handleOuterClick);
    };
  }, []);

  const handleMultiSelectChange = (chosenAttribute: Attribute, index: number) => {
    setSelectedItems(chosenAttribute.options.map(option => option.value));
    setActiveIndex(index);
    onCreateAttribute(chosenAttribute);
  };
  const hoverAndActiveBgClass = 'bg-gray-200';

  const renderAttributeButton = (attribute: Attribute, index: number) => (
    <div
      key={attribute.value}
      className={`mb-2 flex flex-row grow w-full items-center justify-between rounded-lg px-3 py-2 text-left text-black hover:${hoverAndActiveBgClass} ${activeIndex === index ? hoverAndActiveBgClass : ''}`}
      onMouseEnter={() => showMultiSelect(index)}
    >
      {attribute.label}
      <img
        src={chevron}
        className="pl-16"
        draggable={false}
      />
    </div>
  );

  const handleOuterClick = (event: MouseEvent) => {
    if (parentRef.current && !parentRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  const showMultiSelect = (index: number) => {
    if (index !== activeIndex) {
      setSelectedItems([]);
    }
    setActiveIndex(index);
  };


  const renderActiveMultiSelect = (index: number | null) => {
    if (index === null || (attributes[index] === undefined)) {
      return null;
    }

    const attribute = attributes[index];
    return (
      <div className="ml-2">
        <CriteriaListbox
          index={index}
          selectedItems={selectedItems}
          attribute={attribute}
          onSave={handleMultiSelectChange}
        />
      </div>
    );
  };

  return (
    <div className="relative">
      <div
        ref={parentRef}
        className="absolute bottom-16 left-0 flex flex-row max-h-96"
      >
        <div className="flex flex-col shrink-0 w-72 overflow-y-auto rounded-2xl bg-white p-3 shadow-lg">
          {attributes.map(renderAttributeButton)}
        </div>
        <div className="overflow-y-auto p-fieldset bg-transparent">
          {renderActiveMultiSelect(activeIndex)}
        </div>
      </div>
    </div>
  );
};

export default NestedDropdown;
