import React, { useMemo, useState } from 'react';
import AnswerResultBar from './answer-result-bar';
import {
  AnswerSource, AnswerStats, BaseStats, SurveyAudienceCriteria,
} from '../../redux/survey/survey.types';
import QuestionImageUpload from '../image-upload/question-image-upload/question-image-upload';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';
import DemographicsList from "./demographics-list.component";
import FeedbackList from "./feedback-list";
import {CombinedQuestionStats} from "../../pages/view-survey/view-survey.types";
import {orderBy} from "lodash";
import AISummary from "../../pages/view-survey/ai-summary.component";

const { TapAllResults, TapHumanResults, TapSyntheticResults } = AnalyticEventsEnum;
const { All, AI, Human } = AnswerSource;
interface SurveyResultCardProps {
  question: CombinedQuestionStats;
  answers: AnswerStats[];
  criteria?: SurveyAudienceCriteria;
}

const SurveyResultCard: React.FC<SurveyResultCardProps> = ({ criteria, question, answers }) => {
  const [answerFilter, setAnswerFilter] = useState<AnswerSource>(All);
  const hasImageAnswers = answers.some(answer => answer.imageUrl);
  const {showFeedback, summaries } = useMemo(() => {
    return {
      showFeedback: question.hasFeedback && answers.some(answer => answer.feedbacks?.aiAnswers.length || answer.feedbacks?.humanAnswers.length || answer.feedbacks?.rtrAnswers.length),
      summaries: question.summaries?.length ? question.summaries : undefined,
    };
  }, [question, answers]);

  const percentageKey = (): keyof AnswerStats => {
    switch (answerFilter) {
      case Human:
        return "humanPercentage";
      case AI:
        return "aiPercentage";
      default:
        return "totalPercentage";
    }
  };
  const countKey = (): keyof BaseStats => {
    switch (answerFilter) {
      case Human:
        return "humanUsers";
      case AI:
        return "aiUsers";
      default:
        return "totalUsers";
    }
  };
  const { maxPercentage, sortedAnswers } = useMemo(() => {
    const percentages: number[] = [];
    answers.forEach(answer => {
      const stat = answer[percentageKey()];
      if (typeof stat === "number") {
        percentages.push(stat);
      }
    });
    return { maxPercentage: Math.max(...percentages), sortedAnswers: orderBy(answers, countKey(), 'desc') };
  }, [answerFilter, answers]);

  const handleFilterAll = () => {
    AnalyticsManager.trackEvent(TapAllResults);
    setAnswerFilter(All);
  };

  const handleFilterHuman = () => {
    AnalyticsManager.trackEvent(TapHumanResults);
    setAnswerFilter(Human);
  };

  const handleFilterAI = () => {
    AnalyticsManager.trackEvent(TapSyntheticResults);
    setAnswerFilter(AI);
  };

  const renderAnswer = (answer: AnswerStats) => {
    const percentage = Number(answer[percentageKey()] ?? 0);
    const count = Number(answer[countKey()] ?? 0);

    return (
      <AnswerResultBar
        key={answer.title}
        imgSrc={answer.imageUrl}
        topAnswer={percentage !== 0 && percentage === maxPercentage}
        totalCount={count}
        answerLabel={answer.title}
        userPercent={percentage}
        hasImageAnswers={hasImageAnswers}
      />
    );
  };

  return (
    <div className="mb-6 rounded-lg border shadow-md bg-white">
      <div className="p-4">
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center content-between">
          <h2 className="mb-2 text-lg flex grow order-2 lg:order-1">
            {question.title}
          </h2>
          <div className="h-9 flex flex-row order-1 lg:order-2 w-full lg:w-fit shrink-0 justify-end gap-4 lg:ml-2 mb-2">
            <button
              onClick={handleFilterAll}
              className={`shrink-0 py-1 px-2 rounded-xl ${answerFilter === All ? "bg-custom-gray" : ""}`}
            >
              {`All (${question.totalUsers})`}
            </button>
            <button
              onClick={handleFilterHuman}
              className={`shrink-0 py-1 px-2 rounded-xl ${answerFilter === Human ? "bg-custom-gray" : ""}`}
            >
              {`Human (${question.humanUsers})`}
            </button>
            <button
              onClick={handleFilterAI}
              className={`shrink-0 py-1 px-2 rounded-xl text-ai-purple ${answerFilter === AI ? "bg-custom-gray" : ""}`}
            >
              {`Synthetic (${question.aiUsers})`}
            </button>
          </div>
        </div>
        {question.imageUrl && (
          <QuestionImageUpload
            viewImage={true}
            questionId={question?.id || ""}
            extSurveyId={""}
            imageSrc={question?.imageUrl || null}
          />
        )}
        {summaries && (
          <div className="mb-4">
            <AISummary summaries={summaries} />
          </div>
        )}
        <ul className="list-inside list-disc pb-4">
          {sortedAnswers.map((answer) => renderAnswer(answer))}
        </ul>
      </div>
      {showFeedback && (
        <FeedbackList
          answers={sortedAnswers}
          sourceFilter={answerFilter}
        />
      )}
      <DemographicsList
        question={question}
        answers={sortedAnswers}
        criteria={criteria}
        sourceFilter={answerFilter}
      />
    </div>
  );
};

export default SurveyResultCard;
