import {invisiblyApi} from "../api/invisibly-api";
import {PaymentCreationResponse, PaymentStatusData, PaymentStatusResponse} from "./payment.types";
import {PerSurveyPricing} from "../../services/firebase/firebase.types";
import {SurveyStatus} from "../survey/survey.types";
import {surveyEndpoints} from "../survey/survey.endpoints";

const paymentEndpoints = invisiblyApi.injectEndpoints({
  endpoints: (builder) => ({
    createPayment: builder.mutation<string, { extSurveyId: string, pricing: PerSurveyPricing }>({
      query: ({ extSurveyId, pricing }) => ({
        data: { ext_survey_id: extSurveyId, base_question_subtotal: pricing.baseQuestionSubtotal, base_price_question_limit: pricing.basePriceQuestionLimit, over_limit_question_subtotal: pricing.overLimitQuestionSubtotal, per_criteria_price: pricing.perCriteriaPrice, ai_base_question_subtotal: pricing.aiBaseQuestionSubtotal, ai_over_limit_question_subtotal: pricing.aiOverLimitQuestionSubtotal, ai_per_criteria_price: pricing.aiPerCriteriaPrice, ai_base_price_question_limit: pricing.basePriceQuestionLimit },
        method: 'post',
        url: 'payment',
      }),
      transformResponse: (response: PaymentCreationResponse) => response.item.client_secret,
    }),
    getPaymentStatus: builder.query<PaymentStatusData, { sessionId: string }>({
      query: ({ sessionId }) => ({
        method: 'get',
        url: `payment/status/${sessionId}`,
      }),
      transformResponse: (response: PaymentStatusResponse) => ({
        sessionPaymentStatus: response.item.session_payment_status,
        extSurveyId: response.item.ext_survey_id,
        surveyStatus: response.item.survey_status as SurveyStatus,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          const surveyId = response.data.extSurveyId;
          const status = response.data.surveyStatus;
          if (surveyId && status) {
            dispatch(surveyEndpoints.util.updateQueryData('getSurvey', { ext_survey_id: surveyId }, (draft) => {
              const patch = draft;
              patch.surveyStatus = status;
              Object.assign(draft, patch);
            }));
          }
        } catch (error) {
          console.error('payment status error:', error);
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useCreatePaymentMutation, useGetPaymentStatusQuery } = paymentEndpoints;
