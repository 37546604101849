import { ReactElement } from 'react';
import {Answer} from "../../../redux/survey/survey.types";
import {partial} from "lodash";
import {Button} from "primereact/button";

export interface SurveyAnswerListProps {
  readonly answers: Answer[];
  readonly activeAnswerIds: string[];
  readonly onAnswerClick: (answerId: string) => void;
}

const SurveyAnswerList = ({ answers, onAnswerClick, activeAnswerIds }: SurveyAnswerListProps): ReactElement => {
  function renderImage(imageUrl: string): ReactElement | null {
    if (!imageUrl) {
      return null;
    }

    return (
      <div className='flex'>
        <img
          src={imageUrl}
          alt='answer related image'
          className='object-cover min-h-12 max-h-12 min-w-12 max-w-12 rounded-xl'
        />
      </div>
    );
  }

  const renderAnswer = (answer: Answer): ReactElement => {
    const activeButton = activeAnswerIds.indexOf(answer.id) >= 0 ? 'bg-blue-500 text-white' : 'bg-white';
    const imgAdjustedButton = answer.imageUrl ? 'text-left py-2' : 'justify-center py-4';

    return (
      <li key={answer.id}>
        <Button
          className={`${activeButton} ${imgAdjustedButton} flex w-full rounded-2xl px-2 gap-3 text-sm items-center`}
          onClick={partial(onAnswerClick, answer.id)}
        >
          {renderImage(answer.imageUrl ?? '')}
          <span>
            {answer.title}
          </span>
        </Button>
      </li>
    );
  };

  return (
    <ul className='flex flex-col gap-2'>
      {answers.map(renderAnswer)}
    </ul>
  );
};

export default SurveyAnswerList;
