/* eslint-disable @typescript-eslint/naming-convention */
// ALL EVENT NAMES SHOULD BE LESS THAN 40 CHARACTERS
// sheet to simplify adding events to this enum
export enum AnalyticEventsEnum {
  AddCriteria = 'ADD_CRITERIA',
  AddSurveyAnswer = 'ADD_SURVEY_ANSWER',
  AddSurveyQuestion = 'ADD_SURVEY_QUESTION',
  ApplicationType = 'SURVEY_DASHBOARD',
  ContactSupport = 'TAP_CONTACT_SUPPORT',
  ContinueWithApple = 'CONTINUE_WITH_APPLE',
  ContinueWithGoogle = 'CONTINUE_WITH_GOOGLE',
  CopySurveyLink = 'COPY_SURVEY_LINK',
  CreateSurvey = 'CREATE_SURVEY',
  CreateWithEmail = 'CREATE_WITH_EMAIL',
  EditCompanyName = 'EDIT_COMPANY_NAME',
  EditCompanySize = 'EDIT_COMPANY_SIZE',
  EditFirstName = 'EDIT_FIRST_NAME',
  EditLastName = 'EDIT_LAST_NAME',
  EditRole = 'EDIT_ROLE',
  EnterCompanyName = 'ENTER_COMPANY_NAME',
  EnterCompanySize = 'ENTER_COMPANY_SIZE',
  EnterFirstName = 'ENTER_FIRST_NAME',
  EnterLastName = 'ENTER_LAST_NAME',
  EnterSurveyAnswer = 'ENTER_SURVEY_ANSWER',
  EnterSurveyCategory = 'ENTER_SURVEY_CATEGORY',
  EnterSurveyName = 'ENTER_SURVEY_NAME',
  EnterSurveyQuestion = 'ENTER_SURVEY_QUESTION',
  RegisteredUser = 'REGISTERED_USER',
  SaveCriteria = 'SAVE_CRITERIA',
  SelectAISampleSize = 'SELECT_AI_SAMPLE_SIZE',
  SelectAnswerTypeMulti = 'SELECT_ANSWER_TYPE_MULTI',
  SelectAnswerTypeSingle = 'SELECT_ANSWER_TYPE_SINGLE',
  SelectAudienceType = 'SELECT_AUDIENCE_TYPE',
  SelectHumanSampleSize = 'SELECT_HUMAN_SAMPLE_SIZE',
  SendResetLink = 'SEND_RESET_LINK',
  SetNewPassword = 'SET_NEW_PASSWORD',
  SignInWithEmail = 'SIGN_IN_WITH_EMAIL',
  TapAddFilter = 'TAP_ADD_FILTER',
  TapAllResults = 'TAP_ALL_RESULTS',
  TapCancelDuringModeration = 'TAP_CANCEL_DURING_MODERATION',
  TapChooseRole = 'TAP_CHOOSE_ROLE',
  TapContinueProfile = 'TAP_CONTINUE_PROFILE',
  TapCreateAccount = 'TAP_CREATE_ACCOUNT',
  TapDeleteSurvey = 'TAP_DELETE_SURVEY',
  TapDeleteQuestion = 'TAP_DELETE_QUESTION',
  TapForgotPassword = 'TAP_FORGOT_PASSWORD',
  TapHumanResults = 'TAP_HUMAN_RESULTS',
  TapLogOut = 'TAP_LOG_OUT',
  TapPayLaunch = 'TAP_PAY_LAUNCH',
  TapPreviewSurvey = 'TAP_PREVIEW_SURVEY',
  TapSetCondition = 'TAP_SET_CONDITION',
  TapSignIn = 'TAP_SIGN_IN',
  TapSyntheticResults = 'TAP_SYNTHETIC_RESULTS',
  TapProfile = 'TAP_PROFILE',
  ToggleAnswerImageOff = 'TOGGLE_ANSWER_IMAGE_OFF',
  ToggleAnswerImageOn = 'TOGGLE_ANSWER_IMAGE_ON',
  ToggleQuestionImageOff = 'TOGGLE_QUESTION_IMAGE_OFF',
  ToggleQuestionImageOn = 'TOGGLE_QUESTION_IMAGE_ON',
  ToggleRandomizeOff = 'TOGGLE_RANDOMIZE_OFF',
  ToggleRandomizeOn = 'TOGGLE_RANDOMIZE_ON',
  UploadSurveyImage = 'UPLOAD_SURVEY_IMAGE',
  ViewSurvey = 'VIEW_SURVEY',
  ViewSurveyResults = 'VIEW_SURVEY_RESULTS'
}
