import { defaultUserState, IUserState } from './user/user.initial-state';
import {ISurveyState} from "./admin/survey/survey.types";
import {defaultSurveyState} from "./admin/survey/survey.initial-state";
import { defaultNotificationState } from './notification/notification.initial-state';
import { defaultSaveStatus, defaultSurveyFull, SaveStatus, SurveyFull } from './survey/survey.types';
import {IMessagesList} from "./notification/notification.types";
import {defaultSurveyToggleState, SurveyToggleState} from "./survey/survey-targeting-toggle.slice";

// TODO move surveyState out of admin
export interface IState {
  b2bSurveyState: SurveyFull;
  surveyState: ISurveyState;
  userState: IUserState;
  notificationState: IMessagesList;
  saveState: SaveStatus;
  surveyToggleState: SurveyToggleState;
}

export default function createState(): IState {
  return {
    b2bSurveyState: defaultSurveyFull,
    surveyState: defaultSurveyState,
    userState: defaultUserState,
    notificationState: defaultNotificationState,
    saveState: defaultSaveStatus,
    surveyToggleState: defaultSurveyToggleState,
  };
}

export function emptyState(): IState {
  return {
    b2bSurveyState: defaultSurveyFull,
    surveyState: defaultSurveyState,
    userState: defaultUserState,
    notificationState: defaultNotificationState,
    saveState: defaultSaveStatus,
    surveyToggleState: defaultSurveyToggleState,
  };
}

export type ReduxState = IState;
