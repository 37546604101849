import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Panel } from 'primereact/panel';
import {useCreateAdminSurveyMutation} from "../../redux/admin/survey/survey.endpoints";
import { ApiStatus } from '../../services/api/api-response.types';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { ToggleButton } from 'primereact/togglebutton';
import {useSetAdminPageTitle} from "../../hooks/useSetPageTitle";


function SurveyCreateCsvScreen() {
  useSetAdminPageTitle('CSV survey');
  const [title, setTitle] = useState<string>('');
  const [points, setPoints] = useState<string>('');
  const [csv, setCsv] = useState<File>();
  const [image, setImage] = useState<File>();
  const [submitCsv, { isSuccess, error }] = useCreateAdminSurveyMutation();
  const [visible, setVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [startDate, setStartDate] = useState<Nullable<Date>>(null);
  const [endDate, setEndDate] = useState<Nullable<Date>>(null);
  const [published, setPublished] = useState<boolean>(true);

  const categories = ['You', 'Entertainment', 'Tech', 'Community', 'Business', 'Lifestyle', 'Food & Drink', 'Professional', 'Retail', 'Law & Politics', 'Animals', 'Parenting', 'Home & Garden', 'Auto', 'Product Feedback'];

  useEffect(() => {
    if (isSuccess) {
      setDialogTitle('Success');
      setDialogMessage('Survey created!');
      setVisible(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setDialogTitle('Error');
      setDialogMessage((error as ApiStatus).status.status_message);
      setVisible(true);
    }
  }, [error]);

  function createSurvey(): void {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('points', points);
    formData.append('category', category);
    formData.append('file', csv || '');
    formData.append('image_file', image || '');
    formData.append('start_date', startDate == null ? '' : startDate.toISOString());
    formData.append('end_date', endDate == null ? '' : endDate.toISOString());
    formData.append('survey_status', 'live');
    void submitCsv(formData);
  }

  return (
    <div>
      <div className="justify-content-center flex grid flex-col flex-wrap w-1/2 mx-auto">
        <div className="col p-8">
          <Panel
            header="Create Survey"
            className='rounded-lg drop-shadow-lg content-center'
          >

            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <InputText
                className='xm-1 border-2 rounded-md p-2 w-full'
                placeholder="Points"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
              />
            </div>
            <div className="col-1 m-2">
              <Dropdown
                className='xm-1 border-2 rounded-md p-2 w-full'
                value={category}
                onChange={(e) => setCategory(e.value)}
                options={categories}
                placeholder="Select a Category"
              />
            </div>
            <div className="col m-2">
              <FileUpload
                mode="basic"
                onSelect={(e) => setCsv(e.files[0])}
                chooseLabel="CSV"
                className='border-2 p-2 w-full rounded-md'
                accept="text/csv"
              />
            </div>
            <div className="col m-2">
              <FileUpload
                mode="basic"
                onSelect={(e) => setImage(e.files[0])}
                chooseLabel="Image"
                className='border-2 p-2 w-full rounded-md'
                accept="image/*"
                maxFileSize={1000000}
              />
            </div>
            <div className="col m-2">
              <Calendar
                placeholder='Start Date'
                showTime
                hourFormat="12"
                onChange={(e) => setStartDate(e.value)}
                className='border-2 p-2 w-full rounded-md'
                hideOnDateTimeSelect={true}
              />
            </div>

            <div className="col m-2">
              <Calendar
                placeholder='End Date'
                showTime
                hourFormat="12"
                onChange={(e) => setEndDate(e.value)}
                className='border-2 p-2 w-full rounded-md'
                hideOnDateTimeSelect={true}
              />
            </div>

            <div className="col m-2">
              <ToggleButton
                className='border-2 rounded-md'
                checked={published}
                onLabel='Published'
                offLabel='Not Published'
                onChange={(e) => setPublished(e.value)}
              />

            </div>
            <div className="col m-2">
              <Button
                label='Create'
                className='border-2 p-2 w-full bg-blue-400 text-white rounded-md'
                onClick={createSurvey}
              />
            </div>
          </Panel>
          <Dialog
            header={dialogTitle}
            visible={visible}
            style={{ width: '50vw' }}
            onHide={() => setVisible(false)}
          >
            <p className="m-0">
              { dialogMessage }
            </p>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default SurveyCreateCsvScreen;
