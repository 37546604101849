import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import { useSearchParams} from 'react-router-dom';
import SurveyResultCard from '../components/survey-results/survey-results-card';
import {useGetSurveyQuery, useGetSurveyStatsQuery, useGetSurveySummaryQuery} from '../redux/survey/survey.endpoints';
import {QuestionStats, SurveyAudienceCriteria, SurveyStatus} from '../redux/survey/survey.types';
import SurveyImageUpload from '../components/image-upload/survey-image-upload/survey-image-upload';
import {useSetPageTitle} from "../hooks/useSetPageTitle";
import QuestionSummary from "../components/survey-summary-card/survey-summary-component/survey-summary-component";
import ResultsTargeting from "./view-survey/results-targeting.component";
import ResultFilterList from "./view-survey/result-filter-list.component";
import {CombinedQuestionStats} from "./view-survey/view-survey.types";
import SurveyWrapper from "./view-survey/survey-wrapper.component";
import FirebaseService from "../services/firebase/firebase.service";
import {isProd} from "../helpers/env.helpers";
import useIsTabActive from "../hooks/useIsTabActive";
import AISummary from "./view-survey/ai-summary.component";

const ViewSurvey: React.FC = () => {
  const refreshConfig = FirebaseService.getRemoteConfigNumber('RESULTS_AUTO_REFRESH_SECONDS') || 0;
  useSetPageTitle('Survey results');
  const [searchParams] = useSearchParams();
  const extSurveyId = searchParams.get('ext-survey-id') ?? '';
  const [refreshInterval, setRefreshInterval] = useState<number | undefined>();
  const { data: statsData } = useGetSurveyStatsQuery({ ext_survey_id: extSurveyId }, { skip: !extSurveyId, refetchOnMountOrArgChange: true, pollingInterval: refreshInterval });
  const { data: surveyData } = useGetSurveyQuery({ ext_survey_id: extSurveyId }, { skip: !extSurveyId, refetchOnMountOrArgChange: true, pollingInterval: refreshInterval });
  const showSummaries = surveyData?.surveyStatus === SurveyStatus.Complete;
  const { data: summaryData } = useGetSurveySummaryQuery({ ext_survey_id: extSurveyId }, { skip: !showSummaries });
  const audienceId = surveyData?.audience ?? '';
  const [filterCriteria, setFilterCriteria] = useState<SurveyAudienceCriteria | undefined>(undefined);
  const isTabActive = useIsTabActive();

  useEffect(() => {
    // On QA or Perf, most surveys are not answered by real users
    // so we should stop refreshing after AI finishes
    let blockedByAICompletion = false;
    if (!isProd() && surveyData && surveyData.maxAICompletes !== null && surveyData.maxAICompletes > 0 && statsData) {
      blockedByAICompletion = statsData.aiUsers >= surveyData.maxAICompletes;
    }

    const shouldRefresh = refreshConfig && surveyData?.surveyStatus === SurveyStatus.Live && !blockedByAICompletion && isTabActive;
    setRefreshInterval(shouldRefresh ? refreshConfig * 1000 : undefined);
  }, [refreshConfig, surveyData, statsData, isTabActive]);

  const questions = useMemo(() => {
    if (!statsData || !surveyData) return [];

    const questionStats: CombinedQuestionStats[] = statsData.questions.map((question: QuestionStats) => {
      const hasFeedback = surveyData.questions.find(q => q.id === question.id)?.hasFeedback || false;
      const summaries = showSummaries ? summaryData?.questions.find(q => q.id === question.id)?.summaries : undefined;

      return { ...question, hasFeedback, summaries };
    });
    return questionStats;
  }, [statsData, surveyData, summaryData]);

  const onFilterChange = (filter: SurveyAudienceCriteria) => {
    setFilterCriteria(filter);
  };

  const renderLeftPanel = (): ReactElement => {
    return (
      <>
        {statsData?.imageUrl && (
          <SurveyImageUpload
            extSurveyId={extSurveyId}
            src={statsData?.imageUrl || null}
            viewImage={true}
          />
        ) }
        {showSummaries && summaryData?.summaries?.length ? (
          <div className="mb-6 p-6 rounded-lg border shadow-md bg-white">
            <AISummary summaries={summaryData.summaries} />
          </div>
        )
          : null}
        {questions.map((question, index: number) => (
          <SurveyResultCard
            criteria={filterCriteria}
            key={question.title + index}
            question={question}
            answers={question.answers}
          />
        ))}
      </>
    );
  };

  const renderRightPanel = (): ReactElement => {
    return (
      <div className="flex flex-col gap-3 mb-4">
        <QuestionSummary
          extSurveyId={extSurveyId}
          mode="view"
        />
        { audienceId && <ResultsTargeting audienceId={audienceId} /> }
        <ResultFilterList
          audienceId={audienceId}
          onFilterChange={onFilterChange}
        />
      </div>
    );
  };

  return (
    <SurveyWrapper
      headerProps={{ extSurveyId: extSurveyId, mode: 'view' }}
      leftContent={renderLeftPanel()}
      rightContent={renderRightPanel()}
    />
  );
};

export default ViewSurvey;
