import {
  Survey,
  SurveyJSON,
  SurveyFullJSON,
  SurveyFull,
  SurveyQuestionJSON,
  Question,
  Answer,
  SurveyAnswerJSON,
  SurveyAudience,
  SurveyAudienceJSON,
  SurveyAudienceSmallJSON,
  SurveyAudienceSmall,
  toSurveyStatus,
  ModerationResponse,
  ModerationCheckResults,
  ModerationIssue,
  QuestionCreationData,
  SurveyQuestionType,
  SurveyStatsJSON,
  SurveyQuestionStatsJSON,
  BaseStatsJSON,
  BaseStats,
  AnswerStats,
  SurveyAnswerStatsJSON,
  QuestionStats,
  SurveyStats,
  DemographicsKey,
  DemographicsStats,
  AllFeedbackJSON,
  AllFeedback,
  Feedback,
  FeedbackJSON,
  FeedbackUserType,
  SurveySummaryDataJSON, SurveySummaryData,
} from "../../redux/survey/survey.types";
import {formatModerationKey, isModerationItem} from "./surveys.helpers";
import {forIn} from "lodash";

interface ISurveyService {
  readonly parseListSurvey: (json: SurveyJSON) => Survey,
  readonly parseSurvey: (survey: SurveyFullJSON, forResults?: boolean) => SurveyFull,
  readonly parseQuestion: (question: SurveyQuestionJSON, forResults?: boolean) => Question,
  readonly parseAnswer: (answer: SurveyAnswerJSON) => Answer,
  readonly parseAudience: (audience: SurveyAudienceJSON) => SurveyAudience,
  readonly parseAudienceSmall: (audience: SurveyAudienceSmallJSON) => SurveyAudienceSmall,
  readonly parseModerationResponse: (response: ModerationResponse) => ModerationCheckResults,
  readonly createQuestionData: (extSurveyId: string, questionOrder: number) => QuestionCreationData,
  readonly parseSurveyStats: (response: SurveyStatsJSON) => SurveyStats,
  readonly parseSurveySummary: (response: SurveySummaryDataJSON) => SurveySummaryData,
}

class SurveyService implements ISurveyService {
  static instance: SurveyService;

  public static getInstance(): ISurveyService {
    if (!SurveyService.instance) {
      SurveyService.instance = new SurveyService();
    }
    return SurveyService.instance;
  }

  public parseListSurvey = ({ id, created_at, created_by, ext_survey_id, title, completed_users, max_completes, start_date, survey_status, question_count, ai_max_completes, ai_completed_users }: SurveyJSON): Survey => ({
    id,
    completedUsers: completed_users,
    aiCompletedUsers: ai_completed_users,
    createdAt: created_at,
    createdBy: created_by,
    extSurveyId: ext_survey_id,
    title,
    maxCompletes: max_completes,
    maxAICompletes: ai_max_completes,
    startDate: start_date,
    surveyStatus: survey_status,
    questionCount: question_count,
  });

  public parseAnswer = ({id, title, answer_pixel, answer_branch, is_final_question, image_url, user_percent, total_count}: SurveyAnswerJSON): Answer => ({
    id,
    title,
    answerPixel: answer_pixel,
    answerBranch: answer_branch,
    isFinalQuestion: is_final_question,
    imageUrl: image_url,
    userPercent: user_percent,
    totalCount: total_count,
  });

  public parseQuestion = ({ id, title, type, points, answers, response_ids, shuffle, next_question, image_url, total_respondents, question_order, has_feedback = false}: SurveyQuestionJSON, forResults: boolean = false): Question => {
    let parsedAnswers: Answer[] = [];
    if (Array.isArray(answers) && answers.length > 0) {
      parsedAnswers = answers.map(answer => this.parseAnswer(answer));
      if (forResults) {
        parsedAnswers.sort((a, b) => b.totalCount - a.totalCount);
      }
    }
    return {
      id,
      title,
      type,
      points,
      answers: parsedAnswers,
      responseIds: response_ids,
      shuffle,
      nextQuestion: next_question,
      imageUrl: image_url,
      totalRespondents: total_respondents,
      questionOrder: question_order,
      hasFeedback: has_feedback,
    };

  };


  public parseSurvey = ({ title, ext_survey_id, points, questions, audience, category, image_url, rank, ranked_at, start_date, end_date, is_published, survey_status, max_completes, ai_max_completes }: SurveyFullJSON, forResults: boolean = false): SurveyFull => {
    let parsedQuestions: Question[] = [];
    if (Array.isArray(questions) && questions.length > 0) {
      parsedQuestions = questions.map(question => this.parseQuestion(question, forResults));
    }
    return {
      title,
      extSurveyId: ext_survey_id,
      points,
      questions: parsedQuestions,
      audience,
      category,
      imageUrl: image_url,
      rank,
      rankedAt: ranked_at,
      startDate: start_date,
      endDate: end_date,
      isPublished: is_published,
      maxCompletes: max_completes,
      maxAICompletes: ai_max_completes,
      surveyStatus: toSurveyStatus(survey_status),
    };
  };

  public parseAudience = ({ criteria, total_users, id, created_at, updated_at, title, surveys, status, created_by}: SurveyAudienceJSON): SurveyAudience => {
    return {
      criteria: criteria,
      totalUsers: total_users,
      id,
      createdAt: created_at,
      updatedAt: updated_at,
      title,
      surveys,
      status,
      createdBy: created_by,
    };
  };

  public parseAudienceSmall = ({ criteria, total_users, id}: SurveyAudienceSmallJSON): SurveyAudienceSmall => {
    return {
      criteria: criteria,
      totalUsers: total_users,
      id,
    };
  };

  public parseModerationResponse = (response: ModerationResponse): ModerationCheckResults => {
    const didPass = response.item.survey_status === 'pass';
    const entries = Object.entries(response.item);
    const issues: ModerationIssue[] = entries.flatMap(([key, value]) => {
      if (isModerationItem(value) && value.status === 'fail') {
        return [{issueKey: formatModerationKey(key), offendingText: value.text, concernString: value.concerns.join(',')}];
      }

      return [];
    });

    return {
      count: issues.length,
      didPass,
      issues,
    };
  };

  public parseBaseStats = (json: BaseStatsJSON, parentStats?: BaseStats): BaseStats => {
    const { total_users, ai_users, human_users, image_url, rtr_users, title } = json;

    const aiPercentage = (parentStats ? ai_users / parentStats.aiUsers : 0);
    const humanPercentage = (parentStats ? human_users / parentStats.humanUsers : 0);
    const rtrPercentage = (parentStats ? rtr_users / parentStats.rtrUsers : 0);
    const totalPercentage = (parentStats ? total_users / parentStats.totalUsers : 0);
    return {
      totalUsers: total_users,
      aiUsers: ai_users,
      aiPercentage: (!isNaN(aiPercentage) ? aiPercentage : 0) * 100,
      humanUsers: human_users,
      humanPercentage: (!isNaN(humanPercentage) ? humanPercentage : 0) * 100,
      imageUrl: image_url ?? undefined,
      rtrUsers: rtr_users,
      rtrPercentage: (!isNaN(rtrPercentage) ? rtrPercentage : 0) * 100,
      title,
      totalPercentage: (!isNaN(totalPercentage) ? totalPercentage : 0) * 100,
    };
  };

  public parseAnswerStats = (json: SurveyAnswerStatsJSON, totalQuestionAnswers: number, parentStats?: BaseStats, demographicsBase?: DemographicsStats): AnswerStats => {
    const baseStats = this.parseBaseStats(json, parentStats);
    let demographics: DemographicsStats | undefined = undefined;
    let feedbacks: AllFeedback | undefined = undefined;

    if (json.demographics) {
      const demos: DemographicsStats = {};

      Object.entries(json.demographics)
        .filter(([eKey]) => Object.values(DemographicsKey).includes(eKey as DemographicsKey))
        .forEach(([key, value]) => {
          demos[key as DemographicsKey] = Object.values(value)
            .map(demo => {
              const matchingDemo = demographicsBase?.[key as DemographicsKey]?.find(d => d.title === demo.title);
              return this.parseBaseStats(demo, matchingDemo ?? baseStats);
            });
        });

      demographics = demos;
    }

    if (json.feedback) {
      feedbacks = this.parseAnswerFeedback(json.feedback);
    }

    return {
      ...baseStats,
      id: json.id,
      demographics,
      feedbacks,
    };
  };

  public parseAnswerFeedback = (json: AllFeedbackJSON): AllFeedback => {
    const { ai_answers, human_answers, rtr_answers } = json;

    const aiAnswers = this.parseFeedbackList(ai_answers, 'ai');
    const humanAnswers = this.parseFeedbackList(human_answers, 'human');
    const rtrAnswers = this.parseFeedbackList(rtr_answers, 'rtr');

    return {
      aiAnswers,
      humanAnswers,
      rtrAnswers,
    };
  };

  public parseFeedbackList = (feedback: FeedbackJSON[], type: FeedbackUserType): Feedback[] => {
    return feedback ? feedback.map(({ feedback, created_at }) => ({ feedback, createdAt: created_at, userType: type })) : [];
  };

  createQuestionDemographics = (answers: AnswerStats[]) => {
    let demographics: DemographicsStats = {};

    answers.forEach(answer => {
      if (answer.demographics) {
        forIn(answer.demographics, (value, key) => {
          if (!demographics[key as DemographicsKey]) {
            demographics[key as DemographicsKey] = [];
          }

          const matchingDemo = demographics[key as DemographicsKey];
          value?.forEach((demo) => {
            const existingDemo = matchingDemo?.find(d => d.title === demo.title);
            if (existingDemo) {
              existingDemo.totalUsers += demo.totalUsers;
              existingDemo.aiUsers += demo.aiUsers;
              existingDemo.humanUsers += demo.humanUsers;
              existingDemo.rtrUsers += demo.rtrUsers;
            } else {
              matchingDemo?.push(demo);
            }
          });
          demographics = {
            ...demographics,
            [key as DemographicsKey]: matchingDemo,
          };
        });
      }
    });

    return demographics;
  };

  public parseSurveyQuestionStats = (json: SurveyQuestionStatsJSON): QuestionStats => {
    const baseStats = this.parseBaseStats(json);
    const answers = Object.values(json.answers).map(answer => this.parseAnswerStats(answer, baseStats.totalUsers, baseStats));
    const demographics = this.createQuestionDemographics(answers);

    return {
      ...baseStats,
      id: json.id,
      answers: Object.values(json.answers).map(answer => this.parseAnswerStats(answer, baseStats.totalUsers, baseStats, demographics)),
      demographics,
    };
  };

  public parseSurveyStats = (response: SurveyStatsJSON): SurveyStats => {
    const baseStats = this.parseBaseStats(response);
    const questions = Object.values(response.questions).map(question => this.parseSurveyQuestionStats(question));
    return {
      ...baseStats,
      extSurveyId: response.ext_survey_id,
      questions,
    };
  };

  public createQuestionData = (extSurveyId: string, questionOrder: number): QuestionCreationData => {
    return {
      title: "Untitled_Question",
      question_order: questionOrder,
      survey: extSurveyId,
      ext_survey_id: extSurveyId,
      shuffle: true,
      type: SurveyQuestionType.SingleSelect,
    };
  };

  public parseSurveySummary = (response: SurveySummaryDataJSON): SurveySummaryData => {
    const questions = Object.entries(response.questions).map(question => {
      return {
        id: question[0],
        summaries: question[1].summaries,
      };
    });
    return {
      summaries: response.survey.summaries,
      questions,
    };
  };
}

export default SurveyService.getInstance();

