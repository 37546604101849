import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetSurveyAudienceQuery,
  useGetSurveyQuery,
  useUpdateQuestionOrderMutation,
} from '../../../redux/survey/survey.endpoints';
import { defaultQuestion, Question } from '../../../redux/survey/survey.types';
import { OrderList, OrderListChangeEvent, OrderListPassThroughOptions } from "primereact/orderlist";
import './survey-summary-component.css';
import SurveySummaryQuestion from "./survey-summary-question.component";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../redux/notification/notification.slice";
import { isEqual } from "lodash";
import { CombinedSurveyPricing, surveyPricing } from "../survey-summary.helpers";
import FirebaseService from "../../../services/firebase/firebase.service";
import { PerSurveyPricing } from "../../../services/firebase/firebase.types";
import {isQuestionList} from "../../../services/surveys/surveys.helpers";

interface QuestionSummaryProps {
  extSurveyId: string;
  mode: 'create' | 'view';
}

const PASS_THROUGH_OPTIONS: OrderListPassThroughOptions = {
  container: { className: 'flex min-w-0 grow' },
  controls: { className: 'hidden' },
  list: { className: 'bg-transparent min-h-min w-full min-w-0 max-w-full grow' },
  item: { className: 'p-0 bg-transparent w-full min-w-0 max-w-full flex flex-row grow' },
  root: { className: 'max-w-full grow' },
};

const QuestionSummary: React.FC<QuestionSummaryProps> = ({ extSurveyId, mode }) => {
  const configPricing = FirebaseService.getRemoteConfigJSON<PerSurveyPricing>('PER_SURVEY_PRICING');
  const criteriaPricing = ((configPricing?.perCriteriaPrice || 0) / 100).toFixed(2);
  const [pricing, setPricing] = useState<CombinedSurveyPricing | undefined>(undefined);
  const dispatch = useDispatch();
  const [updateQuestionOrder] = useUpdateQuestionOrderMutation();
  const { data: surveyData } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const { data: surveyAudience } = useGetSurveyAudienceQuery({ id: surveyData?.audience || "" }, { skip: !surveyData?.audience });

  const [questions, setQuestions] = useState(surveyData?.questions || [defaultQuestion]);
  useEffect(() => {
    if (!surveyData?.questions) {
      return;
    }
    const orderedQuestions = [...surveyData.questions].sort((a, b) => a.questionOrder - b.questionOrder);
    setQuestions(orderedQuestions);
    const criteriaCount = Object.keys(surveyAudience?.criteria || {}).length;
    setPricing(surveyPricing(surveyData.questions.length, criteriaCount, surveyData?.maxCompletes ?? 0, surveyData.maxAICompletes ?? 0));
  }, [surveyData, surveyAudience]);

  const { questionCount } = useMemo(() => {
    return {
      questionCount: `${questions.length} question${questions.length !== 1 ? 's' : ''}`,
    };
  }, [questions]);

  const renderQuestion = (q: Question) => (
    <SurveySummaryQuestion question={q} />
  );
  const renderQuestionView = (q: Question, index: number) => (
    <div
      key={index}
      className={
        index + 1 === questions.length
          ? 'flex w-full items-center rounded-b-xl border-t border-gray-200 bg-white px-2 py-3 text-black'
          : 'flex w-full items-center border-t border-gray-200 bg-white px-2 py-3 text-black'
      }
    >
      <div className="mr-2 shrink-0 px-2 text-gray-400">
        {index + 1}
      </div>
      <div className="grow truncate text-start">
        {q.title}
      </div>
    </div>
  );
  const renderAttribute = () => (
    <>
      {Object.entries(surveyAudience?.criteria || {}).map(([key], index) => (
        <div
          key={index}
          className="flex w-full items-center justify-between pt-1"
        >
          <div className="text-gray-400">
            {key}
          </div>
          <div className="text-gray-400">
            {`$${criteriaPricing}`}
          </div>
        </div>
      ))}
    </>
  );

  const onQuestionListChange = (event: OrderListChangeEvent) => {
    if (!isQuestionList(event.value)) {
      return;
    }

    const previousIds = questions.map(({ id }) => id);
    const updatedIds = event.value.map(({ id }) => id);
    if (isEqual(previousIds, updatedIds)) {
      return;
    }

    updateQuestionOrder({ ext_survey_id: extSurveyId, questionArray: updatedIds });
  };

  const onListDragStart = () => {
    const hasSorting = questions.some(q => q.answers.some(a => a.answerBranch !== null || a.isFinalQuestion));
    if (hasSorting) {
      dispatch(showNotification({ summary: "Sorting the questions will clear all answer branching within the survey 'Jump to' or 'End Survey'", title: "Warning", theme: "info" }));
    }
  };

  return (
    <>
      {mode === 'create' ? (
        <div>
          <div className="question-summary-list flex overflow-hidden md:max-h-[300px]">
            <OrderList
              dragdrop
              onDragStart={onListDragStart}
              dataKey="id"
              value={questions}
              pt={PASS_THROUGH_OPTIONS}
              itemTemplate={renderQuestion}
              onChange={onQuestionListChange}
            />
          </div>
          <div className="my-4 rounded-xl bg-white shadow-md">
            <div className="flex w-full items-center justify-between border-b border-gray-200 p-3">
              <div className="text-black">
                {questionCount}
              </div>
              <div className="text-black">
                $
                {pricing?.human.questionsTotal}
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-between border-b border-gray-200 p-3">
              <div className="flex w-full justify-between">
                <div className="text-black">
                  Targeting
                </div>
                <div className="text-black">
                  $
                  {pricing?.human.targetingTotal}
                </div>
              </div>
              {renderAttribute()}
            </div>
            {Boolean(surveyData?.maxCompletes) && (
              <div className="flex w-full items-center justify-between border-b border-gray-200 p-3">
                <div className="text-black">
                  Human respondents subtotal (x
                  {surveyData?.maxCompletes}
                  )
                </div>
                <div className="text-black">
                  $
                  {pricing?.human.subTotal}
                </div>
              </div>
            )}
            {Boolean(surveyData?.maxAICompletes) && (
              <div className="flex w-full items-center justify-between border-b border-gray-200 p-3">
                <div className="text-black">
                  Synthetic users subtotal (x
                  {surveyData?.maxAICompletes}
                  )
                </div>
                <div className="text-black">
                  $
                  {pricing?.ai.subTotal}
                </div>
              </div>
            )}
            <div className="flex w-full items-center justify-between border-b border-gray-200 p-3">
              <div className="text-black">
                Total
              </div>
              <div className="text-2xl text-black">
                {pricing?.totalString}
              </div>
            </div>
          </div>
        </div>
      )
        : (
          <div className="flex flex-col justify-start rounded-xl bg-white shadow-md">
            <div className="ml-4 py-3 text-gray-400">
              Questions
            </div>
            {questions.map((q, index) => renderQuestionView(q, index))}
          </div>
        )
      }

    </>
  );
};

export default QuestionSummary;
