import { Card } from 'primereact/card';
import { Title } from '../typography/title';
import { SubTitle } from '../typography/subtitle';
import { Survey, SurveyStatus } from "../../redux/survey/survey.types";
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';
import {useEffect, useMemo, useRef, useState} from 'react';
import { useDeleteSurveyMutation, useGetSurveysQuery, useGetSurveyShareLinkQuery } from '../../redux/survey/survey.endpoints';
import SurveyMenu from '../survey-header/survey-header-menu';
import { SURVEY_GRID_PAGE_SIZE } from "../../services/surveys/survey.constants";
import ellipsis from '../../assets/icons/ellipses.svg';
import { computeColor, displaySurveyStatus } from '../survey-status/survey-status-utils';
import DeleteSurveyDialog from '../delete-survey-dialog/delete-survey-dialog';
import { formatDisplayDate } from "../../helpers/date.helpers";
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/notification/notification.slice';
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

const { CopySurveyLink, ViewSurvey, ViewSurveyResults } = AnalyticEventsEnum;
const { Complete, Draft, Live, Review, Rejected } = SurveyStatus;

interface SurveyCardProps extends Survey {
  currentPage: number,
}

export const SurveyCard = ({
  surveyStatus,
  createdAt,
  extSurveyId,
  title,
  completedUsers,
  aiCompletedUsers,
  startDate,
  maxCompletes,
  maxAICompletes,
  questionCount,
  currentPage,
}: SurveyCardProps) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteSurvey] = useDeleteSurveyMutation();
  const { data: shareLink } = useGetSurveyShareLinkQuery({ ext_survey_id: extSurveyId }, { skip: !extSurveyId || surveyStatus !== Live });
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchStatus = () => {
    switch (surveyStatus) {
      case Live:
        return Live;
      case Complete:
        return Complete;
      default:
        return [Draft, Review, Rejected].join(',');
    }
  };
  const { bg: statusBgColor, text: statusTextColor } = computeColor(surveyStatus);
  const showSurveyResults = surveyStatus === Live || surveyStatus === Complete;

  const { refetch } = useGetSurveysQuery({ survey_status: fetchStatus(), page: currentPage, page_size: SURVEY_GRID_PAGE_SIZE }, { skip: false });

  useEffect(() => {
    const handler = (e: any) => {
      if (!menuRef.current?.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  const { subtitle } = useMemo(() => {
    let subtitleText = `${questionCount ?? 0} question`;
    if (questionCount !== 1) {
      subtitleText += 's';
    }

    if (!showSurveyResults) {
      return { subtitle: subtitleText };
    }

    if (completedUsers !== null || aiCompletedUsers !== null) {
      const totalCompleted = (completedUsers ?? 0) + (aiCompletedUsers ?? 0);
      const totalMaxCompletes = (maxCompletes ?? 0) + (maxAICompletes ?? 0);
      subtitleText += ` · ${totalCompleted}${totalMaxCompletes ? '/' + totalMaxCompletes : ''}`;
    }

    subtitleText += ` · ${formatDisplayDate(startDate || createdAt)}`;

    return {
      subtitle: subtitleText,
    };
  }, [questionCount, surveyStatus, completedUsers, maxCompletes, maxAICompletes, startDate, createdAt]);

  const handleCardClick = () => {
    if (isMenuOpen) {
      return;
    }

    AnalyticsManager.trackEvent(showSurveyResults ? ViewSurveyResults : ViewSurvey, { surveyStatus });
    const canEdit = surveyStatus === Draft || surveyStatus === Rejected || surveyStatus === Review;
    navigate((canEdit ? `/create-survey` : '/view-survey') + `?ext-survey-id=${extSurveyId}`);
  };
  const handleIconClick = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  const deleteSurveyCall = async() => {
    try {
      await deleteSurvey({ ext_survey_id: extSurveyId }).unwrap();
      refetch();
      setIsMenuOpen(false);
      handleCloseModal();
    } catch (error) {
      console.error('Failed to delete survey:', error);
    }
  };

  const onDuplicateClick = () => {
    // todo surveycard onDuplicateClick
    console.log("surveycard onDuplicateClick");
  };

  const handleCopyShareLink = () => {
    if (!shareLink) {
      dispatch(showNotification({ summary: 'Failed to copy survey link to clipboard', theme: 'warning' }));
      return;
    }

    AnalyticsManager.trackEvent(CopySurveyLink);
    navigator.clipboard.writeText(shareLink);
    dispatch(showNotification({ summary: 'Survey link was successfully copied to clipboard', theme: 'success' }));
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Card
      className="relative flex h-44 w-full cursor-pointer flex-col justify-center rounded-lg px-2 pb-5 transition-transform duration-300 ease-in-out hover:bg-custom-gray active:scale-95"
      onClick={handleCardClick}
    >
      <div className='absolute left-6 top-4 flex flex-row w-5/6 justify-between'>
        <div className='flex items-center'>
          <div className={`w-2.5 h-2.5 rounded-full mr-2 text-xs ${statusBgColor}`} />
          <p className={`${statusTextColor}`}>
            {displaySurveyStatus[surveyStatus]}
          </p>
        </div>
        <div
          className={`flex relative z-20 items-center justify-center w-8 h-8 ${surveyStatus === Complete && "hidden"}`}
          onClick={handleIconClick}
          ref={menuRef}
        >
          <img
            src={ellipsis}
            alt="ellipsis"
            className="cursor-pointer size-4"
            draggable={false}
          />
          <div className="relative z-20" >
            {isMenuOpen && (
              <SurveyMenu
                viewScreen={false}
                surveyCard={true}
                onCopyLinkClick={surveyStatus === Live ? handleCopyShareLink : undefined}
                onDeleteClick={!showSurveyResults ? handleOpenModal : undefined}
                onDuplicateClick={onDuplicateClick}
              />
            )}
            <DeleteSurveyDialog
              deleteSurvey={deleteSurveyCall}
              isModalOpen={isModalOpen}
              handleCloseModal={handleCloseModal}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 flex-1">
        <Title
          text={title}
          className="line-clamp-2 break-words text-[1.75rem] leading-tight"
        />
      </div>
      <div className='absolute bottom-5 mt-10'>
        <SubTitle text={subtitle} />
      </div>
    </Card>
  );
};
