import FirebaseService from "../../services/firebase/firebase.service";
import {PerSurveyPricing} from "../../services/firebase/firebase.types";

export interface SurveySummaryPricingData {
  readonly questionsTotal: string;
  readonly targetingTotal: string;
  readonly subTotal: string;
  readonly total: number;
}

export interface CombinedSurveyPricing {
  readonly human: SurveySummaryPricingData;
  readonly ai: SurveySummaryPricingData;
  readonly totalString: string
}

export const surveyPricing = (questionCount: number, criteriaCount: number, humanResponseCount: number, aiResponseCount: number): CombinedSurveyPricing | undefined => {
  const pricing = FirebaseService.getRemoteConfigJSON<PerSurveyPricing>('PER_SURVEY_PRICING');

  if (!pricing) {
    return undefined;
  }

  const { baseQuestionSubtotal, basePriceQuestionLimit, overLimitQuestionSubtotal, perCriteriaPrice, aiBaseQuestionSubtotal, aiOverLimitQuestionSubtotal, aiPerCriteriaPrice } = pricing;

  const humanQuestionTotal = questionCount <= basePriceQuestionLimit ? baseQuestionSubtotal : baseQuestionSubtotal + overLimitQuestionSubtotal;
  const humanTargetingTotal = criteriaCount * perCriteriaPrice;
  const humanSubTotal = humanQuestionTotal + humanTargetingTotal;
  const humanPricing: SurveySummaryPricingData = {
    questionsTotal: (humanQuestionTotal / 100).toFixed(2),
    targetingTotal: (humanTargetingTotal / 100).toFixed(2),
    subTotal: (humanSubTotal / 100).toFixed(2),
    total: humanSubTotal * humanResponseCount,
  };
  const aiQuestionTotal = questionCount <= basePriceQuestionLimit ? aiBaseQuestionSubtotal : aiBaseQuestionSubtotal + aiOverLimitQuestionSubtotal;
  const aiTargetingTotal = criteriaCount * aiPerCriteriaPrice;
  const aiSubTotal = aiQuestionTotal + aiTargetingTotal;
  const aiPricing: SurveySummaryPricingData = {
    questionsTotal: (aiQuestionTotal / 100).toFixed(2),
    targetingTotal: (aiTargetingTotal / 100).toFixed(2),
    subTotal: (aiSubTotal / 100).toFixed(2),
    total: aiSubTotal * aiResponseCount,
  };

  const total = humanPricing.total + aiPricing.total;
  const totalString = `$${(total / 100).toFixed(2)}`;

  return { human: humanPricing, ai: aiPricing, totalString };
};
