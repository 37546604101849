import {useEffect, useRef, useState} from 'react';
import {RadioButtonGrid, RadioButtonGridProps, RadioButtonGridType} from '../radio-button/radio-button';
import Criteria from './criteria';
import {
  useCreateSurveyAudienceMutation,
  useGetSurveyAudienceQuery,
  useGetSurveyQuery,
  useUpdateSurveyAudienceMutation,
  useUpdateSurveyMutation,
} from '../../redux/survey/survey.endpoints';
import {Attribute, convertToAttributes} from './criteria-settings';
import FirebaseService from "../../services/firebase/firebase.service";
import {SurveySampleSizes} from "../../services/firebase/firebase.types";
import {SURVEY_MINIMUM_TARGET_SIZE} from "../../services/surveys/survey.constants";
import AnalyticsManager from '../../services/analytics/analytics.service';
import {AnalyticEventsEnum} from '../../services/analytics/analytic-event-enum';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/redux-store";
import {setSurveyTargetingToggle} from "../../redux/survey/survey-targeting-toggle.slice";

const { AI, Human, Targeting } = RadioButtonGridType;
const { ContactSupport, SelectAISampleSize, SelectAudienceType, SelectHumanSampleSize } = AnalyticEventsEnum;

interface CriteriaCardProps {
  extSurveyId: string;
}

const CriteriaCard = ({ extSurveyId }: CriteriaCardProps) => {
  const config = FirebaseService.getRemoteConfigJSON<SurveySampleSizes>('SAMPLE_SIZES');
  const targetingConfig = FirebaseService.getRemoteConfigBool('SURVEYS_DEFAULT_TARGETED');
  const { human, ai } = config || { human: [], ai: [] };
  const nonZeroHuman = human.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const nonZeroAI = ai.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const { data: fullSurvey } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const [updateSurvey] = useUpdateSurveyMutation();
  const [audienceId, setAudienceId] = useState<string>('');
  const { data } = useGetSurveyAudienceQuery({ id: audienceId }, { skip: !audienceId });
  const [createSurveyAudience, { data: createData }] = useCreateSurveyAudienceMutation();
  const [updateSurveyAudience] = useUpdateSurveyAudienceMutation();
  const [totalUsers, setTotalUsers] = useState(typeof fullSurvey?.maxCompletes === 'number' ? String(fullSurvey.maxCompletes) : String(nonZeroHuman));
  const [totalSynthetic, setTotalSynthetic] = useState(typeof fullSurvey?.maxAICompletes === 'number' ? String(fullSurvey.maxAICompletes) : String(nonZeroAI));
  const [initialCriteria, setInitialCriteria] = useState<Attribute[]>();
  const isTargeted = useSelector((state: RootState) => state.surveyToggleState.isTargeted[extSurveyId] ?? targetingConfig);
  const isFirstDataFetch = useRef(true);
  const dispatch = useDispatch();

  const humanProps: RadioButtonGridProps = {
    extSurveyId: extSurveyId,
    title: 'Human sample size',
    buttons: human.map((size) => ({ label: String(size), value: String(size) })),
    disabledForZero: totalSynthetic == "0",
    type: Human,
  };

  const aiProps: RadioButtonGridProps = {
    extSurveyId: extSurveyId,
    title: 'Synthetic sample size',
    buttons: ai.map((size) => ({ label: String(size), value: String(size) })),
    disabledForZero: totalUsers == "0",
    type: AI,
  };

  const targetProps: RadioButtonGridProps = {
    extSurveyId: extSurveyId,
    title: 'Target audience',
    buttons: [
      { label: 'Targeted', value: 'targeted' },
      { label: 'Random', value: 'random' },
    ],
    type: Targeting,
  };

  useEffect(() => {
    createData && setAudienceId(createData.id);
  }, [createData]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setInitialCriteria(convertToAttributes(data.criteria));

    if (isFirstDataFetch.current && Object.keys(data.criteria).length) {
      dispatch(setSurveyTargetingToggle({ surveyId: extSurveyId, isTargeted: true }));
    }

    if (isFirstDataFetch.current) {
      isFirstDataFetch.current = false;
    }
  }, [data]);

  useEffect(() => {
    if (!fullSurvey) { return; }
    if (fullSurvey.audience) {
      setAudienceId(fullSurvey.audience);
    } else {
      setInitialCriteria([]);
    }
  }, [fullSurvey]);

  const handleCriteriaChange = async(criteria: any) => {
    const surveyAudienceData = {
      criteria: criteria,
      survey: extSurveyId,
      total_users: Number(totalUsers),
    };
    if (!audienceId) {
      void createSurveyAudience({ data: surveyAudienceData, ext_survey_id: extSurveyId || "" });
    } else {
      const criteriaData = {
        criteria: criteria,
      };
      updateSurveyAudience({ id: audienceId, data: criteriaData });
    }
  };

  const handleTotalRespondents = (value: string) => {
    const numberValue = Number(value);
    updateSurvey({
      ext_survey_id: extSurveyId,
      title: fullSurvey?.title || "",
      max_completes: numberValue,
    });
    AnalyticsManager.trackEvent(SelectHumanSampleSize, { size: value });
    setTotalUsers(value);
  };

  const handleTotalSynthetic = (value: string) => {
    const numberValue = Number(value);
    updateSurvey({
      ext_survey_id: extSurveyId,
      title: fullSurvey?.title || "",
      ai_max_completes: numberValue,
    });
    AnalyticsManager.trackEvent(SelectAISampleSize, { size: value });
    setTotalSynthetic(value);
  };

  const handleTargeted = (value: string) => {
    if (value == "random" && audienceId) {
      resetAudience();
    }

    AnalyticsManager.trackEvent(SelectAudienceType, { type: value });
    dispatch(setSurveyTargetingToggle({ surveyId: extSurveyId, isTargeted: value === 'targeted' }));
  };

  const resetAudience = () => {
    const criteriaData = {
      criteria: {},
    };
    updateSurveyAudience({ id: audienceId, data: criteriaData });
  };

  const handleContactEvent = () => {
    AnalyticsManager.trackEvent(ContactSupport);
  };

  return (
    <div>
      <div className="mb-4 text-2xl">
        Target
      </div>
      <div className="rounded-lg bg-white p-8 shadow-md">
        <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="w-full">
            <RadioButtonGrid
              onClick={handleTotalRespondents}
              {...humanProps}
              initialValue={totalUsers}
            />
            <div className="mt-2 text-sm font-medium text-gray-400">
              Need more than 250 respondents?
            </div>
            <div className="text-sm mb-6 text-gray-400">
              <a
                onClick={handleContactEvent}
                href="mailto:support@invisibly.com?subject=Invisibly%20-%20Enterprise%20Pricing%20Inquiry"
                className="text-gray-400 underline mr-1"
              >
                Contact us
              </a>
              {"for less expensive Enterprise pricing and support."}
            </div>
            <RadioButtonGrid
              onClick={handleTotalSynthetic}
              {...aiProps}
              initialValue={totalSynthetic}
            />
            <div className="mt-6">
              <RadioButtonGrid
                onClick={handleTargeted}
                {...targetProps}
                initialValue={isTargeted ? 'targeted' : 'random'}
              />
            </div>
          </div>
        </div>
        <div className='mt-8'>
          {isTargeted && initialCriteria && (
            <Criteria
              extSurveyId={extSurveyId}
              initialCriteria={initialCriteria}
              onChange={handleCriteriaChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CriteriaCard;
