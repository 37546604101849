import {ReactElement, useState} from 'react';
import SurveyAnswerList from "./survey-answer-list";
import SurveyPreviewFooter from "./survey-preview-footer";
import {Question, SurveyQuestionType} from "../../../redux/survey/survey.types";
import {UseSurveyPreview} from "../useSurveyPreview";

const {MultiSelect} = SurveyQuestionType;
export interface PhoneContentsProps {
  readonly questions: Question[];
  readonly surveyPreviewProps: UseSurveyPreview;
}

const SurveyPreview = ({ questions, surveyPreviewProps }: PhoneContentsProps): ReactElement | null => {
  const [fakeLoading, setFakeLoading] = useState(false);
  const { onPressNext, setActiveAnswerIds, activeAnswerIds, activeQuestionIndex} = surveyPreviewProps;

  if (!questions[activeQuestionIndex]) {
    return null;
  }

  const {title, answers, imageUrl, type} = questions[activeQuestionIndex];

  function handleAnswerClick(answerId: string) {
    if (fakeLoading) {
      return;
    }

    if (type !== MultiSelect) {
      setFakeLoading(true);
      setActiveAnswerIds([answerId]);
      handleDelayNext();
    } else {
      const newAnswerIds = activeAnswerIds.includes(answerId) ? activeAnswerIds.filter(id => id !== answerId) : [...activeAnswerIds, answerId];
      setActiveAnswerIds(newAnswerIds);
    }
  }

  function handleDelayNext() {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
      onPressNext();
    }, 200);
  }

  function renderQuestionImage(): ReactElement | null {
    if (!imageUrl) {
      return null;
    }

    return (
      <div className='flex justify-center -mb-2'>
        <img
          src={imageUrl}
          className='object-cover max-h-[200px] rounded-2xl'
          alt='question related image'
        />
      </div>
    );
  }

  return (
    <div className='flex h-full flex-col justify-between gap-6 px-4 py-6'>
      <div className='no-scrollbar flex flex-col gap-6 overflow-y-scroll'>
        <span className='text-center'>
          {title}
        </span>
        {renderQuestionImage()}
        <SurveyAnswerList
          answers={answers}
          activeAnswerIds={activeAnswerIds}
          onAnswerClick={handleAnswerClick}
        />
      </div>
      <SurveyPreviewFooter
        onPressContinue={onPressNext}
        continueState={type !== MultiSelect ? 'hidden' : activeAnswerIds.length > 0 ? 'visible' : 'disabled'}
        totalQuestions={questions.length}
        activeIndex={activeQuestionIndex}
        isLoading={fakeLoading}
      />
    </div>
  );
};

export default SurveyPreview;
