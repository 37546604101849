
// css
import 'primereact/resources/primereact.min.css';
// theme
import 'primereact/resources/themes/saga-blue/theme.css';
// icons
import 'primeicons/primeicons.css';

import { Routes, Route, Navigate } from "react-router-dom";

import ListSurveysScreen from './pages/admin/survey-list.screen';
import ListAudienceScreen from './pages/admin/audience-list.screen';
import DiscoveriesCreateScreen from './pages/admin/discovery-create.screen';
import ListDiscoveriesScreen from './pages/admin/discovery-list.screen';
import SurveyDetailsScreen from './pages/admin/survey-details.screen';
import Layout from './components/layout';
import SurveyCreateCsvScreen from './pages/admin/survey-create-csv.screen';
import { ReduxState } from "./redux/redux-state";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import InvisiblyApiService from "./services/api/invisibly-api.service";
import ErrorLogService from "./services/error-log/error-log.service";
import { signOut } from "./redux/user/user.async-thunks";
import {getIsUserAuthenticated, getIsUserSuperAdmin} from "./redux/user/user.selectors";
import AuthService from "./services/auth/auth.service";
import { setUser } from "./redux/user/user.slice";
import AsyncStorageService, { AsyncStorageKeys } from "./services/storage/async-storage.service";
import { User } from "./services/user/user.types";
import { v4 as uuid } from "uuid";
import SurveyCreateScreen from './pages/admin/survey-create.screen';
import AudienceCreateScreen from './pages/admin/audience-create.screen';
import CreateAccountPage from './pages/create-account/create-account';
import SetupProfilePage from './pages/setup-profile';
import ConfirmAccountPage from './pages/confirm-account';
import { B2BMessage } from './components/message/b2bmessage';
import SignIn from './pages/sign-in/sign-in';
import SignInScreen from './pages/admin/sign-in.screen';
import DashboardPage from './pages/dashboard/dashboard';
import CreateSurvey from './pages/create-survey/create-survey';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ProfileScreen from './pages/profile';
import ViewSurvey from './pages/view-survey';
import SurveyReviewScreen from './pages/admin/survey-review.screen';
import ResetPasswordPage from './pages/reset-password';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import Four04 from "./pages/404";
import FirebaseService from "./services/firebase/firebase.service";
import AnalyticsService from './services/analytics/analytics.service';
import Payment from "./pages/payment";
import {useFreshpaint} from "./services/analytics/useFreshpaint";

type AppProps = PropsFromRedux;

const App = ({ email, extUserId, isAuthenticated, isSuperAdmin, jwt, authSignOut }: AppProps): JSX.Element => {
  useFreshpaint();
  const [areServicesReady, setAreServicesReady] = useState(false);
  const [isUserReady, setIsUserReady] = useState(false);

  useEffect(() => {
    void initApp();
  }, []);

  useEffect(() => {
    InvisiblyApiService.setAuthorizationToken(jwt);
  }, [jwt]);

  useEffect(() => {
    isAuthenticated && AuthService.initUserAuthInvalidInterceptor(authSignOut);
  }, []);

  useEffect(() => {
    if (areServicesReady && !isUserReady) {
      void (extUserId ? initForUsers() : initForNonUsers());
    }
  }, [extUserId, areServicesReady]);

  async function initApp(): Promise<void> {
    try {
      await FirebaseService.init();
      InvisiblyApiService.init();
    } catch (error) {
      ErrorLogService.logError(error, 'App', 'initApp');
    }

    setAreServicesReady(true);
  }

  function initForUsers(): void {
    void AuthService.linkDeviceIdToUser(extUserId);
    AnalyticsService.setUser();
    setIsUserReady(true);
  }

  async function initForNonUsers(): Promise<void> {
    try {
      let deviceId = await AsyncStorageService.localStorage.retrieveData<string>(AsyncStorageKeys.deviceId);

      // TODO: temp solution to store device as a user yet
      let tokenExists = false;

      if (deviceId) {
        tokenExists = await AuthService.checkDeviceIdLinked(deviceId);
      } else {
        deviceId = uuid();
        await AsyncStorageService.localStorage.storeData(AsyncStorageKeys.deviceId, deviceId);
      }

      let newUser: User | null = null;

      if (tokenExists) {
        //TODO landingScreenOverride.current = 'LandingScreen';
      } else {
        newUser = await AuthService.createNewAnonymousUser(deviceId);
      }

      if (newUser) {
        setUser(newUser);
        setIsUserReady(true);
      } else {
        setIsUserReady(true);
      }
    } catch (error) {
      ErrorLogService.logError(error, 'root-nav', 'reinitiateUserSessionWithToken');
      // landingScreenOverride.current = 'DeviceIdErrorScreen';
      setIsUserReady(true);
    }
  }

  interface RouteWrapperProps {
    children: JSX.Element;
    redirectTo: string;
  }
  function RequireAuth({ children, redirectTo }: RouteWrapperProps) {
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }
  function RequireSuperAdmin({ children, redirectTo }: RouteWrapperProps) {
    return isSuperAdmin ? children : <Navigate to={redirectTo} />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <B2BMessage />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={(
              <Navigate
                to="/login"
                replace
              />
            )}
          />
          <Route
            path='login'
            element={<SignIn />}
          />
          <Route
            path='create-account'
            element={<CreateAccountPage />}
          />
          <Route
            path='setup-profile'
            element={<SetupProfilePage />}
          />
          <Route
            path='confirm-account'
            element={<ConfirmAccountPage />}
          />
          <Route
            path='reset-password'
            element={<ResetPasswordPage />}
          />

          <Route
            path='dashboard'
            element={(
              <RequireAuth redirectTo="/login">
                <DashboardPage />
              </RequireAuth>
            )}
          />
          <Route
            path='create-survey'
            element={(
              <RequireAuth redirectTo="/login">
                <CreateSurvey />
              </RequireAuth>
            )}
          />
          <Route
            path='profile'
            element={(
              <RequireAuth redirectTo="/login">
                <ProfileScreen />
              </RequireAuth>
            )}
          />
          <Route
            path='view-survey'
            element={(
              <RequireAuth redirectTo="/login">
                <ViewSurvey />
              </RequireAuth>
            )}
          />
          <Route
            path='payment'
            element={(
              <RequireAuth redirectTo="/login">
                <Payment />
              </RequireAuth>
            )}
          />


          <Route
            path="admin"
            element={(
              <RequireSuperAdmin redirectTo={'/login'}>
                <Layout />
              </RequireSuperAdmin>
            )}
          >
            <Route
              path="login"
              element={<SignInScreen />}
            />
            <Route
              path="surveys"
              element={<ListSurveysScreen />}
            />
            <Route
              path="surveys/csv"
              element={<SurveyCreateCsvScreen />}
            />
            <Route
              path="surveys/create"
              element={<SurveyCreateScreen />}
            />
            <Route
              path="surveys/:id"
              element={<SurveyDetailsScreen />}
            />
            <Route
              path="audiences/create"
              element={<AudienceCreateScreen />}
            />
            <Route
              path="audiences"
              element={<ListAudienceScreen />}
            />
            <Route
              path="discoveries"
              element={<ListDiscoveriesScreen />}
            />
            <Route
              path='review'
              element={<SurveyReviewScreen />}
            />
            <Route
              path="discoveries/create"
              element={<DiscoveriesCreateScreen />}
            />
          </Route>

          <Route
            path='*'
            element={<Four04 />}
          />
        </Routes>
      </div>
    </I18nextProvider>
  );
};


const mapStateToProps = (state: ReduxState) => ({
  email: state.userState.email,
  extUserId: state.userState.extUserId,
  isAuthenticated: getIsUserAuthenticated(state),
  isSuperAdmin: getIsUserSuperAdmin(state),
  jwt: state.userState.jwt,
});

const mapDispatchToProps = {
  authSignOut: signOut,
  setUserState: setUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(App);
