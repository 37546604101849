import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../backbutton/backbutton';
import preview from '../../assets/icons/preview.svg';
import { clearSurveyState } from '../../redux/survey/survey.slice';
import ReduxService from '../../redux/redux.service';
import { useDeleteSurveyMutation, useGetSurveyQuery, useUpdateSurveyMutation } from '../../redux/survey/survey.endpoints';
import SurveyMenu from './survey-header-menu';
// import invoice from '../../assets/icons/invoice.svg';
// import download from '../../assets/icons/download.svg';
import ellipses from '../../assets/icons/ellipses.svg';
import { SurveyStatus } from '../../redux/survey/survey.types';
import SaveStatus from '../save-status/save-status';
import { SaveStatusEnum } from '../../redux/survey/survey.types';
import { setSaveState } from '../../redux/survey/survey-status.slice';
import { useDispatch } from 'react-redux';
import { setSaveStateWithDelay } from '../../redux/survey/survey-status.utils';
import SurveyPreviewModal from "../survey-preview-modal/survey-preview-modal";
import { partial } from "lodash";
import { displaySurveyStatus, computeColor } from '../survey-status/survey-status-utils';
import DeleteSurveyDialog from '../delete-survey-dialog/delete-survey-dialog';
import { formatDisplayDate } from "../../helpers/date.helpers";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';

const { EnterSurveyName, TapPreviewSurvey } = AnalyticEventsEnum;

export interface SurveyHeaderProps {
  extSurveyId: string;
  mode: 'create' | 'view';
}

const SurveyHeader: React.FC<SurveyHeaderProps> = ({ extSurveyId, mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>('');
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>(SurveyStatus.Draft);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );

  const [updateSurvey] = useUpdateSurveyMutation();
  const [deleteSurvey] = useDeleteSurveyMutation();
  const [inputValue, setInputValue] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [inputWidth, setInputWidth] = useState('240px');
  const minWidth = 240;

  const measureTextWidth = (text: string): number => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '1.5rem Arial';
      const width = context.measureText(text).width;
      const calculatedWidth = width + 50; // Adjust as needed
      const constrainedWidth = Math.max(minWidth, calculatedWidth);
      return constrainedWidth + 20; // Additional padding as needed
    }
    return minWidth;
  };

  useEffect(() => {
    const updateInputWidth = () => {
      const viewportWidth = window.innerWidth;
      const textWidth = measureTextWidth(inputValue);
      const maxWidth = viewportWidth * 0.5; // Example: 80% of the viewport width

      setInputWidth(`${Math.min(textWidth, maxWidth)}px`);
    };

    updateInputWidth();
    window.addEventListener('resize', updateInputWidth);
    return () => {
      window.removeEventListener('resize', updateInputWidth);
    };
  }, [inputValue]);

  useEffect(() => {
    if (data) {
      if (data.title != "Untitled") {
        setInputValue(data.title);
        setTitle(data.title);
      }
      setSurveyStatus(data.surveyStatus || SurveyStatus.Draft);
    }
  }, [data]);
  const handleBackButtonClick = () => {
    ReduxService.Store.dispatch(clearSurveyState());
    navigate(-1);
  };

  const handlePreviewClick = () => {
    AnalyticsManager.trackEvent(TapPreviewSurvey);
    setPreviewVisible(true);
  };

  const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    dispatch(setSaveState({ status: SaveStatusEnum.Syncing }));
    setInputValue(e.target.value);
  };
  const handleBlur = async() => {
    if (inputValue.trim() === '') {
      return;
    }
    AnalyticsManager.trackEvent(EnterSurveyName);
    await updateSurvey({
      title: inputValue.trim(),
      ext_survey_id: extSurveyId,
    });
    setSaveStateWithDelay(
      dispatch,
      SaveStatusEnum.JustSynced,
      SaveStatusEnum.Synced,
      5000
    );
  };


  const deleteSurveyCall = async() => {
    try {
      deleteSurvey({ ext_survey_id: extSurveyId });
      setIsMenuOpen(false);
      handleCloseModal();
      handleBackButtonClick();
    } catch (error) {
      console.error('Failed to delete survey:', error);
    }
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const onDuplicateClick = () => {
    // todo survey-header onDuplicateClick
    console.log("survey-header onDuplicateClick");
  };
  const handleMenuOpen = () => {
    setIsMenuOpen((prev) => !prev);
  };
  useEffect(() => {
    const handler = (e: any) => {
      if (!menuRef.current?.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  const handleViewInvoice = () => {
    // todo survey-header handleViewInvoice
    console.log('survey-header handleViewInvoice');
  };
  const handleDownload = () => {
    // todo survey-header handleDownload
    console.log('survey-header handleDownload');
  };

  const { bg: statusBgColor, text: statusTextColor } = computeColor(surveyStatus);

  const SurveyStatusComponent = () => (
    <div className="flex flex-row items-center">
      <div className='mt-1 ml-6 flex items-center'>
        <div className={`w-2.5 h-2.5 rounded-full mr-2 text-xs ${statusBgColor}`} />
        <p className={`${statusTextColor}`}>
          {displaySurveyStatus[surveyStatus]}
        </p>
      </div>
      <div className="mt-2 ml-3 text-sm text-gray-400 overflow-hidden text-ellipsis whitespace-nowrap">
        {formatDisplayDate(data?.endDate ?? undefined)}
      </div>
    </div>
  );

  function renderTitle(): ReactElement {
    return (
      <div className="flex-1 sm-max:w-1/2">
        {mode == "create" ? (
          <div className="flex md:flex-row items-center flex-grow ">
            <input
              type="text"
              defaultValue={inputValue}
              placeholder="Give it a name..."
              onBlur={handleBlur}
              onChange={handleInputChange}
              disabled={data?.surveyStatus === SurveyStatus.Review}
              className="rounded-md border-none bg-transparent pl-4 py-2 text-lg focus:outline-none overflow-hidden text-ellipsis whitespace-nowrap"
              style={{ fontSize: '1.5rem', width: inputWidth }}
            />
            <div className='flex items-center mb-1 sm-max:hidden'>
              <SurveyStatusComponent />
            </div>
          </div>
        )
          : (
            <div className="w-full md:flex flex-row items-center rounded-md border-none bg-transparent pl-4 py-2 text-lg focus:outline-none ">
              <div
                className="overflow-hidden text-ellipsis whitespace-nowrap text-2xl lg-max:text-xl sm-max:text-lg"
                style={{ maxWidth: '100%' }}
              >
                {title}
              </div>
              <div className='flex items-center md:ml-4'>
                <SurveyStatusComponent />
              </div>
            </div>
          )}
      </div>
    );
  }

  return (
    <div className="h-20 flex w-full items-center justify-between border-b border-gray-200 p-4">
      <div className="flex items-center">
        <BackButton onClick={handleBackButtonClick} />
      </div>
      {renderTitle()}
      {mode == "view" ? (
        <div className="flex flex-row items-center ml-2">
          {/* Needs implementation
          <button
            onClick={handleDownload}
            className="sm-max:hidden flex size-10 flex-row items-center justify-center rounded-3xl bg-white shadow-md"
          >
            <img className="h-4" src={download} alt=""></img>
          </button>
          <button
            onClick={handleViewInvoice}
            className="sm-max:hidden ml-2 flex items-center lg-max:rounded-full lg-max:p-3  rounded-3xl bg-white px-6 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <img className="mr-2 lg-max:m-0 h-4" src={invoice} alt=""></img>
            <span className="lg-max:hidden">View Invoice</span>
          </button> */}
          <button
            onClick={handlePreviewClick}
            className="sm-max:hidden ml-2 flex items-center rounded-3xl bg-white lg-max:p-2 px-6 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <img
              className="mr-2 lg-max:m-0"
              src={preview}
              alt=""
              draggable={false}
            />
            <span className="lg-max:hidden">
              Preview
            </span>
          </button>
          <div
            className="sm-min:hidden mx-4 flex items-center justify-center h-10 w-10 rounded-full bg-white shadow-md"
            ref={menuRef}
          >
            <img
              src={ellipses}
              className="cursor-pointer w-6"
              onClick={handleMenuOpen}
              draggable={false}
            />
            <div className="relative" >
              {isMenuOpen && (
                <SurveyMenu
                  viewScreen={true}
                  handleDownload={handleDownload}
                  handleViewInvoice={handleViewInvoice}
                  handlePreviewClick={handlePreviewClick}
                  onDeleteClick={handleOpenModal}
                  onDuplicateClick={onDuplicateClick}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <div className="lg-max:hidden">
            <SaveStatus />
          </div>
          <button
            onClick={handlePreviewClick}
            className="ml-2 flex items-center rounded-3xl bg-white lg-max:p-2 px-6 py-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <img
              className="mr-2 lg-max:m-0"
              src={preview}
              alt=""
              draggable={false}
            />
            <span className="lg-max:hidden">
              Preview
            </span>
          </button>
          <div
            className="mx-4 flex items-center justify-center h-10 w-10 rounded-full bg-white shadow-md"
            ref={menuRef}
          >
            <img
              src={ellipses}
              className="cursor-pointer w-6"
              onClick={handleMenuOpen}
              draggable={false}
            />
            <div className="relative" >
              {isMenuOpen && (
                <SurveyMenu
                  viewScreen={false}
                  handleDownload={handleDownload}
                  handleViewInvoice={handleViewInvoice}
                  handlePreviewClick={handlePreviewClick}
                  onDeleteClick={handleOpenModal}
                  onDuplicateClick={onDuplicateClick}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <DeleteSurveyDialog
        deleteSurvey={deleteSurveyCall}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
      {previewVisible && data && (
        <SurveyPreviewModal
          questions={data?.questions || []}
          isVisible={previewVisible}
          onHide={partial(setPreviewVisible, false)}
          survey={data}
        />
      )}
    </div>
  );
};

export default SurveyHeader;
