import {RemoteConfigProps} from "./firebase.types";

export const REMOTE_CONFIG_DEFAULTS: RemoteConfigProps = {
  CREATE_ACCOUNT_COPY: `{
    "title": "Create account on Invisibly Research"
  }`,
  CREATE_SURVEY_COPY: `{
    "betaHeader": "Ask questions and get insights from Invisibly app users and trained AI personas. Build surveys for free with code INVBETA. Launch your survey to get instant persona answers. We’ll email you when your survey has completed gathering all respondent answers.",
    "humanSampleTooltip": "Invisibly’s human sample comes from real, opt-in Invisibly app users. This ensures genuine and diverse feedback from engaged participants.",
    "aiSampleTooltip": "Invisibly’s synthetic audience is made up of AI-powered personas that mirror real human behavior and demographics. Go to invisibly.com/business to learn how it works",
    "targetingTooltip": "Target your survey to specific audiences based on demographics, interests, or behaviors for more precise insights. Keep in mind that the more targeted your audience, the longer it may take for our real human users to respond."
  }`,
  DASHBOARD_EMPTY_STATE_COPY: `{
    "emptyStateTitle": "Get answers fast with Invisibly",
    "emptyStateSubtitle": "Build surveys powered by both human insights and AI-driven synthetic audiences for instant, accurate results.",
    "learnMoreHelperText": "about Invisibly Research.",
    "learnMoreLink": "https://www.invisibly.com/business/"
  }`,
  MAX_CRITERIA: 3,
  PER_SURVEY_PRICING: `{
    "baseQuestionSubtotal": 75,
    "basePriceQuestionLimit": 4,
    "overLimitQuestionSubtotal": 50,
    "perCriteriaPrice": 20,
    "aiBaseQuestionSubtotal": 8,
    "aiOverLimitQuestionSubtotal": 5,
    "aiPerCriteriaPrice": 2
  }`,
  RESULTS_AUTO_REFRESH_SECONDS: 15,
  SAMPLE_SIZES: `{
    "human": [0,30,50,100,250],
    "ai": [0,100,250,500,750,1000]
  }`,
  SIGN_IN_COPY: `{
    "title": "Sign into Invisibly Research"
  }`,
  SURVEYS_DEFAULT_TARGETED: false,
};
