import logoPath from "../../assets/images/brand-logo.svg";
import {ReactElement} from "react";
interface AISummaryProps {
  readonly summaries: string[];
}

const AISummary = ({ summaries }: AISummaryProps): ReactElement => {
  return (
    <div className="flex flex-row gap-3" >
      <div className="flex flex-col shrink-0 size-8 rounded-2xl bg-gray-100 items-center justify-center" >
        <img
          src={logoPath}
          alt="Brand Logo"
          className="object-cover w-2 h-[18px]"
          draggable={false}
        />
      </div>
      <div className="flex flex-col gap-3 min-w-0">
        {summaries.map((summary, index) => (
          <div
            key={index}
            className="flex flex-row shrink"
          >
            <div
              className="px-4 py-3 bg-gray-100 rounded-r-lg rounded-bl-lg min-w-0 max-w-full"
            >
              <div className="text-base">
                {summary}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AISummary;
