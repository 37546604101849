import {ModerationItem, Question, SurveyFull} from "../../redux/survey/survey.types";

const findLocalSurveyErrors = (data: SurveyFull): string | undefined => {
  const hasUntitledSurvey = data.title === "Untitled";
  const hasUntitledQuestion = data.questions.some(question => question.title === 'Untitled_Question');
  const answers = data.questions.flatMap(question => question.answers);
  const hasUntitledAnswer = answers.some(answer => answer.title === 'Untitled_Answer');

  if (!hasUntitledSurvey && !hasUntitledQuestion && !hasUntitledAnswer) {
    return undefined;
  }

  let errorMessage = "There was an error launching the survey due to the following issues: \n";
  if (hasUntitledSurvey) {
    errorMessage += " - The survey title is untitled.\n";
  }
  if (hasUntitledQuestion) {
    errorMessage += " - One or more questions are untitled. \n";
  }
  if (hasUntitledAnswer) {
    errorMessage += " - One or more answers are untitled. \n";
  }

  return errorMessage;
};


function isModerationItem(item: ModerationItem | string): item is ModerationItem {
  return typeof item === 'object' && 'status' in item && 'concerns' in item;
}

const formatModerationKey = (key: string): string => {
  return key.replace(/\d+/g, (match) => {
    return (parseInt(match, 10) + 1).toString();
  }).replace(/\./g, ' ')
    .replace(/\b(\w)/g, (match) => match.toUpperCase());
};

const booleanToShuffleMode = (isRandom: boolean) => {
  return isRandom ? 'random' : 'none';
};

const shuffleModeToBoolean = (shuffleMode: string) => {
  return shuffleMode === 'random';
};


function isQuestionList(value: any): value is Question[] {
  return value.every((item: any) => Object.hasOwn(item, 'questionOrder'));
}

export { findLocalSurveyErrors, formatModerationKey, isModerationItem, booleanToShuffleMode, shuffleModeToBoolean, isQuestionList };
