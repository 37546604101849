import { createAppAsyncThunk } from '../redux.helpers';
// TODO: handle circular dependency issue
// import { setErrorData } from '../api/api.slice';

import { invisiblyApi } from "../api/invisibly-api";
import { B2BUser } from '../../services/user/user.types';
import userApiService from '../../services/user/user.api-service';
import NotificationService from '../../services/notification/notification.service';
import { clearUserState, setB2BUserProfile } from './user.slice';
import authService from '../../services/auth/auth.service';
import ReduxService from '../../redux/redux.service';
import ErrorLogService from "../../services/error-log/error-log.service";

// TODO: handle circular dependency issue
// dante todo
// import { invisiblyApi } from '../api/invisibly-api';

// export const setUserAsOnboarded = createAppAsyncThunk('user/setUserAsOnboarded', async(_u) => {
//   try {
//     await authApiService.setOnboarded();
//   } catch (error) {
//     console.error(error, 'user.async-thunks', 'setUserAsOnboarded');
//   }
// });

const setupUserProfile = createAppAsyncThunk<void, B2BUser, { rejectValue: string }>(
  'user/setupUserProfile',
  async(b2bUser: B2BUser, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState();
      const user = await userApiService.setupUserProfile(state.userState.extUserId, b2bUser);
      dispatch(setB2BUserProfile(user));
      NotificationService.handleSuccess(undefined, dispatch, 'Successfully setup profile');
    } catch (error) {
      ErrorLogService.logError(error, 'user.async-thunks', 'setupUserProfile', [b2bUser]);
      const usableError = NotificationService.handleError(error, dispatch, 'An error occurred when trying to setup your profile, please try again later');
      return rejectWithValue(usableError.message);
    }
  });

const editUserProfile = createAppAsyncThunk<void, { id: string, data: B2BUser }, { rejectValue: string }>(
  'user/editUserProfile',
  async({ data }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState();
      const user = await userApiService.editUserProfile(state.userState.extUserId, data);
      dispatch(setB2BUserProfile(user));
    } catch (error) {
      ErrorLogService.logError(error, 'user.async-thunks', 'editUserProfile', [data]);
      const usableError = NotificationService.handleError(error, dispatch, 'An error occurred while trying to update your profile, please try again later');
      return rejectWithValue(usableError.message);
    }
  }
);

const signOut = createAppAsyncThunk('user/signout', async(_u, { dispatch, rejectWithValue }) => {
  try {
    await authService.signOut();
    dispatch(invisiblyApi.util?.resetApiState());
    ReduxService.Store.dispatch(clearUserState());
  } catch (error) {
    ErrorLogService.logError(error, 'user.async-thunks', 'signOut');
    return rejectWithValue();
  }
});

export { setupUserProfile, signOut, editUserProfile };
