import React, { useState, useRef, useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import image from '../../../assets/icons/image.svg';
import './question-image-upload.css';
import { useDeleteImageMutation, useGetSurveyQuery, useUploadImageMutation } from '../../../redux/survey/survey.endpoints';
import { Question, SurveyStatus } from '../../../redux/survey/survey.types';
import { useTranslation } from "react-i18next";
import { ALLOWED_IMAGE_TYPE_STRING } from "../image-uploads.constants";
import { AnalyticEventsEnum } from '../../../services/analytics/analytic-event-enum';
import AnalyticsManager from '../../../services/analytics/analytics.service';
import TooltipIcon from "../../tooltip-icon/tooltip-icon.component";

const { ToggleQuestionImageOff, ToggleQuestionImageOn } = AnalyticEventsEnum;
const { trackEvent } = AnalyticsManager;

interface QuestionImageUploadProps {
  questionId: string;
  extSurveyId: string;
  imageSrc: string | null;
  viewImage?: boolean;
}

const QuestionImageUpload: React.FC<QuestionImageUploadProps> = ({ viewImage, questionId, extSurveyId, imageSrc }) => {
  const { t } = useTranslation();
  const [useQuestionImage, setUseQuestionImage] = useState<boolean>(imageSrc !== null);
  const [uploadedImageLocal, setUploadedImageLocal] = useState<string | null>(imageSrc);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadImage] = useUploadImageMutation();
  const [deleteImage] = useDeleteImageMutation();
  const { data } = useGetSurveyQuery(
    { ext_survey_id: extSurveyId },
    { skip: !extSurveyId }
  );
  const [question, setQuestion] = useState<Question | undefined>(undefined);

  useEffect(() => {
    if (data?.questions) {
      const updatedQuestion = data.questions.find(q => q.id === questionId);
      if (updatedQuestion) {
        setQuestion(updatedQuestion);
      }
    }
  }, [data, questionId]);

  useEffect(() => {
    setUseQuestionImage(imageSrc !== null);
    setUploadedImageLocal(imageSrc);
  }, [imageSrc]);

  const handleImageUpload = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImageLocal(reader.result as string);
        setUploadedImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (uploadedImage) {
      handleUpload();
    }
  }, [uploadedImage]);

  const handleUpload = async() => {
    if (uploadedImage) {
      try {
        const formData = new FormData();
        formData.append('file', uploadedImage);
        formData.append('type', 'question');
        formData.append('uuid', questionId);
        await uploadImage({ formData: formData, ext_survey_id: extSurveyId }).unwrap();
      } catch (error) {
        console.error('Upload Error:', error);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files?.[0];
    if (file && ALLOWED_IMAGE_TYPE_STRING.includes(file.type)) {
      handleImageUpload(file);
    }
  };

  const handleImageRemove = async(event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }
    try {
      if (uploadedImageLocal) {
        await deleteImage({ image_id: questionId, ext_survey_id: extSurveyId, type: "question" }).unwrap();
        setUploadedImage(null);
        setUploadedImageLocal(null);
      }
    } catch (error) {
      console.error('Error deleting question image:', error);
    }
  };

  const handleToggle = () => {
    useQuestionImage ? trackEvent(ToggleQuestionImageOff) : trackEvent(ToggleQuestionImageOn);
    if (useQuestionImage) {
      handleImageRemove();
    }
    setUseQuestionImage(!useQuestionImage);
  };

  return (
    <div>
      {viewImage ? (
        <div className="relative mb-10 flex h-32 w-full items-center justify-center overflow-hidden rounded-lg bg-white shadow-md">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              className="object-cover"
              draggable={false}
            />
          ) : (
            <div className="text-gray-400">
              No Image Available
            </div>
          )}
        </div>
      ) : data?.surveyStatus === SurveyStatus.Review ? (
        <div>
          {question?.imageUrl ? (
            <>
              <div className="mb-2 flex flex-row items-center font-semibold">
                Question image
              </div>
              <div
                className="relative mb-10 flex h-32 w-full items-center justify-center overflow-hidden rounded-lg bg-white shadow-md"
              >
                <img
                  src={question?.imageUrl || image}
                  alt="Question Image"
                  className="object-cover"
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="mb-2 flex flex-row items-center font-semibold">
            Question image
            <div className="upload_question_image flex items-center ml-2">
              <InputSwitch
                checked={useQuestionImage}
                onChange={handleToggle}
                className={`md:-mr-1 custom-switch ${useQuestionImage ? 'p-inputswitch-checked' : ''}`}
              />
              <TooltipIcon
                targetClass="question-image-toggle"
                message="Optional. Insert an image that appears with this specific question."
              />
            </div>
          </div>
          {useQuestionImage ? (
            <div
              className="relative mb-10 flex h-32 w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-white shadow-md"
              onClick={() => fileInputRef.current?.click()}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!uploadedImageLocal ? (
                <span className="ml-2 flex items-center space-x-2 text-gray-400">
                  <img
                    src={image}
                    alt=""
                    className="size-4 object-contain"
                  />
                  <div className="flex">
                    <span className="mr-1 underline">
                      Upload question image
                    </span>
                    {' '}
                    or drag and drop here
                  </div>
                  <div className='absolute bottom-3 left-0 right-0 text-center text-gray-400 text-xs'>
                    {t('createSurvey.imageSizeRecommendation')}
                  </div>
                </span>
              ) : (
                <img
                  src={uploadedImageLocal}
                  alt="Uploaded"
                  className="object-cover"
                />
              )}
              <input
                ref={fileInputRef}
                id="upload_survey_image"
                type="file"
                accept={ALLOWED_IMAGE_TYPE_STRING}
                className="absolute inset-0 hidden"
                onChange={(e) => handleImageUpload(e.target.files?.[0] as File)}
              />
              {uploadedImageLocal && (
                <div className="absolute right-4 top-4">
                  <button
                    className="rounded-2xl bg-white px-4 py-1 text-black"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="mb-5"> </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionImageUpload;
