import {ReactElement, useMemo} from "react";
import {AnswerSource, AnswerStats, BaseStats, DemographicsKey, QuestionStats} from "../../redux/survey/survey.types";
import {DemographicsSection} from "./demographics-list.component";
import {isNaN} from "lodash";

const { AI, All, Human } = AnswerSource;

export interface DemographicsTileProps {
  readonly question: QuestionStats;
  readonly answers: AnswerStats[];
  readonly criteria: DemographicsSection;
  readonly sourceFilter: AnswerSource;
}

type CriteriaGroup = Pick<BaseStats, 'humanUsers' | 'humanPercentage' | 'aiUsers' | 'aiPercentage'> & { maxHuman: number, maxAI: number };
type MaxPercentage = { [key: string]: { maxHuman: number, maxAI: number } };

const DemographicsTile = ({ answers, criteria, question, sourceFilter }: DemographicsTileProps): ReactElement => {
  const matchingDemoKey = criteria.title.toLowerCase().replaceAll(' ', '_') as DemographicsKey;

  const maxPercentages = useMemo(() => {
    const maxPercentage: MaxPercentage = {};
    answers.forEach((answer) => {
      const answerDemographics = answer.demographics?.[matchingDemoKey];
      if (answerDemographics) {
        criteria.values.forEach((value) => {
          const criteriaDemographics = answerDemographics.find((demo) => demo.title.toLowerCase() === value.toLowerCase());
          if (criteriaDemographics) {
            if (!maxPercentage[value]) {
              maxPercentage[value] = { maxHuman: 0, maxAI: 0 };
            }
            maxPercentage[value].maxHuman = Math.max(maxPercentage[value].maxHuman, criteriaDemographics.humanPercentage);
            maxPercentage[value].maxAI = Math.max(maxPercentage[value].maxAI, criteriaDemographics.aiPercentage);
          }
        });
      }
    });
    return maxPercentage;
  }, [criteria, answers]);

  const renderTitleColumn = (): ReactElement => {
    return (
      <div className="flex flex-col text-right w-24 md:w-1/5 md:min-w-32 pr-4 md:pr-8 pb-2">
        <p className="text-sm font-medium">
          {criteria.title}
        </p>
        {criteria.values.map((value) => (
          <div
            key={value}
            className={`mt-3 ${sourceFilter === All ? 'h-10' : 'h-5'}`}
          >
            <p className="text-sm">
              {value}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const renderAnswerBar = (count: number, percentage: number, maxPercentage: number, source: AnswerSource): ReactElement => {
    const isAI = source === AI;
    const rawWidth = (percentage / maxPercentage) * 100;
    const width = Math.max(0, Math.min(100, !isNaN(rawWidth) ? rawWidth : 0));
    return (
      <div className="flex flex-row h-5 gap-3 items-center space-between">
        <div className="flex flex-grow items-center">
          <div
            className={`transition-all h-0.5 min-w-0.5 ${isAI ? 'bg-ai-purple' : 'bg-black'}`}
            style={{ width: `${width}%` }}
          />
        </div>
        <p className={`text-sm text-right shrink-0 ${isAI ? 'text-ai-purple' : ''}`}>
          {percentage.toFixed(0)}
          % (
          {count}
          )
        </p>
      </div>
    );
  };

  const renderAnswerColumn = (answer: AnswerStats): ReactElement | null => {
    if (!answer.demographics) {
      return null;
    }

    const answerDemographics = answer.demographics[matchingDemoKey];
    if (!answerDemographics) {
      return null;
    }

    const criteriaGroups: CriteriaGroup[] = [];
    criteria.values.forEach((value) => {
      const criteriaDemographics = answerDemographics.find((demo) => demo.title === value);

      criteriaDemographics && criteriaGroups.push({...criteriaDemographics, ...maxPercentages[value]});
    });

    return (
      <div
        key={answer.id}
        className="flex flex-col shrink-0 min-w-24 w-1/5"
      >
        <p className={`text-sm truncate`}>
          {answer.title}
        </p>
        {criteriaGroups.map(({ humanUsers, humanPercentage, aiUsers, aiPercentage, maxHuman, maxAI }, index) => (
          <div
            key={`${answer.id} - ${index}`}
            className="flex flex-col mt-3"
          >
            {(sourceFilter === Human || sourceFilter === All) && renderAnswerBar(humanUsers, humanPercentage, maxHuman, Human)}
            {(sourceFilter === AI || sourceFilter === All) && renderAnswerBar(aiUsers, aiPercentage, maxAI, AI)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-custom-gray p-6 mb-2 rounded-lg">
      <div className="flex flex-row">
        {renderTitleColumn()}
        <div className="flex flex-row gap-8 grow overflow-x-auto pb-2">
          {answers.map((answer) => renderAnswerColumn(answer))}
        </div>
      </div>
    </div>
  );
};

export default DemographicsTile;
