import {IMessage} from "./notification.types";
import {generateUniqueId} from "./notification.helpers";

function addNotificationToQueue(notification: IMessage, queue: IMessage[]): IMessage[] {
  const isDuplicate = queue.some(item => {
    const sameTitle = item?.title && item.title === notification.title;
    const sameSummary = item?.summary && item.summary === notification.summary;

    return sameTitle || sameSummary;
  });

  if (!isDuplicate) {
    notification.id = generateUniqueId();
    queue.push(notification);
  }

  return queue;
}

function removeNotificationFromQueue(notification: IMessage, queue: IMessage[]): IMessage[] {
  return queue.filter(item => item.id !== notification.id);
}

export { addNotificationToQueue, removeNotificationFromQueue };
