import {useNavigate, useSearchParams} from 'react-router-dom';
import { BackButton } from '../components/backbutton/backbutton';
import UpdateForm from '../components/form/update-profile-form/update-form';
import { DashboardMenubar } from '../components/menubar/menubar';
import {useSetPageTitle} from "../hooks/useSetPageTitle";

const ProfileScreen = () => {
  useSetPageTitle('Profile');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleBackClick = () => {
    searchParams.get('404') ? navigate('/dashboard') : navigate(-1);
  };

  return (
    <div>
      <DashboardMenubar />
      <div className="flex-column flex justify-center p-6 sm-max:px-0">
        <div className="flex flex-row sm-max:flex-col items-start">
          <BackButton
            theme="light"
            onClick={handleBackClick}
          />
          <div className="flex-column ml-4 sm-max:w-full  sm-max:ml-0 sm-max:mt-2 flex items-center justify-start rounded-2xl bg-white p-10 pb-2 shadow-md">
            <h1 className="mb-2 text-2xl font-bold">
              <UpdateForm />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
