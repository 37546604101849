import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SurveyToggleState {
  isTargeted: { [key: string]: boolean };
}

export const defaultSurveyToggleState: SurveyToggleState = {
  isTargeted: {},
};

export const surveyTargetingToggleSlice = createSlice({
  initialState: defaultSurveyToggleState,
  name: 'surveyTargetingState',
  reducers: {
    clearSurveyTargetingState() {
      return defaultSurveyToggleState;
    },
    setSurveyTargetingToggle(state, { payload }: PayloadAction<{ surveyId: string, isTargeted: boolean }>) {
      if (!state.isTargeted) {
        state.isTargeted = {};
      }

      state.isTargeted[payload.surveyId] = payload.isTargeted;
    },
  },
});

export const {
  clearSurveyTargetingState,
  setSurveyTargetingToggle,
} = surveyTargetingToggleSlice.actions;

export default surveyTargetingToggleSlice.reducer;
