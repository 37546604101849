// import BaseLocalization from '../locales/en';

/** remove white space and convert to lowercase */
export function cleanUpString(text: string): string {
  if (text && text.length > 0) {
    return text.replace(/\s/g, '').toLowerCase();
  }

  return 'Invalid Source';
}

/**
 * Formats to USD. Includes sense for non whole dollar values
 * @param input
 */
export function pointToCurrencyString(input: number, includeEquivalent: boolean = false): string {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    // maximumFractionDigits: 0, enabling this property with a value of 0 causes crashes on Android
    style: 'currency',
  });

  const isWholeDollar = input % 100 === 0;
  const formatted = formatter.format(input / 100);
  const value = isWholeDollar ? formatted.split('.')[0] : formatted;

  return includeEquivalent ? replaceStringHandleBars('Equivalent to {{0}}', [value]) : value;
}

export function replaceStringHandleBars(text: string, args: string[]): string {
  let result = text;

  args.forEach((item, index) => {
    result = result.replace(`{{${index}}}`, item ?? '');
  });
  return result;
}

export function cleanUpPhoneNumber(inputNumber: string): string {
  const hasFullCode = inputNumber.startsWith('+1');
  const hasCountryCode = inputNumber.startsWith('1');
  const hasOtherCountryCode = inputNumber.startsWith('+');

  let checkedNumber = '';

  if (hasFullCode || hasOtherCountryCode) {
    checkedNumber = inputNumber;
  } else {
    checkedNumber = hasCountryCode ? `+${inputNumber}` : `+1${inputNumber}`;
  }

  return checkedNumber;
}

export function camelToSnake(camelCaseString: string): string {
  const snakeCaseString = camelCaseString.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
  return snakeCaseString;
}

export function objectKeysToSnakeCase(obj: any): any {
  if (!obj || typeof obj !== 'object') {
    throw new Error('Input should be a valid object');
  }

  const snakeCasedObject: any = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = camelToSnake(key);
      snakeCasedObject[snakeKey] = obj[key];
    }
  }

  return snakeCasedObject;
}