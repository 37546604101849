export const Config = {
  API_URL: 'https://cd-wallet-qa.pymx6.com/admin',
  CURRENT_ENV: 'QA',
  ENCRYPTION_KEY: 'hJ1kT2YJarwabUf2Vuu-KL8vYqfYmLzGW7pVOaQubYg',
  GOOGLE_WEB_CLIENT_ID: '1044036154671-qrss8p73lunj3og1ss8j1r2a08nsjvhb.apps.googleusercontent.com',
};

export const appleAuthOptions = {
  clientId: process.env.REACT_APP_PUBLIC_APPLE_CLIENT_ID || '',
  redirectURI: process.env.REACT_APP_PUBLIC_WEB_URL || '',
  scope: 'email name',
  state: 'state',
  usePopup: true,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

/** points to  'https://cd-wallet-{{ENV}}.pymx6.com/admin */
export const apiUrl = process.env.REACT_APP_API_URL || Config.CURRENT_ENV;
// @ts-expect-error convenience typecast
export const currentEnv: 'QA' | 'PERF' | 'DEV' | 'PROD' = process.env.REACT_APP_CURRENT_ENV || Config.CURRENT_ENV;
export const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || Config.ENCRYPTION_KEY;
export const webClientId = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID || Config.GOOGLE_WEB_CLIENT_ID;
export const appVersion = process.env.REACT_APP_VERSION || '0.0.0';
export const freshpaintEnvID = process.env.REACT_APP_FRESHPAINT_ENVIRONMENT_ID;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
