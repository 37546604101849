import { Form, Formik } from "formik";
import { B2BButton } from "../../b2bbutton/b2bbutton";
import { DropdownInput } from "../../dropdown/dropdown";
import Input from "../../input/input";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/redux-store";
import { setupUserProfile } from "../../../redux/user/user.async-thunks";
import { getUser } from "../../../redux/user/user.selectors";
import { roles, companySizes } from '../dropdown-settings';
import { userProfileSchema } from '../../../constants/validation.constants';
import AnalyticsManager from '../../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../../services/analytics/analytic-event-enum';

const { EnterCompanyName, EnterCompanySize, EnterFirstName, EnterLastName, TapChooseRole, TapContinueProfile } = AnalyticEventsEnum;

interface FormValues {
  firstName: string;
  lastName: string;
  role: string;
  companyName: string;
  companySize: string;
}

const SetupProfileForm = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => getUser(state));

  const initialValues = {
    firstName: userState.firstName ? userState.firstName : '',
    lastName: userState.lastName ? userState.lastName : '',
    role: userState.role ? userState.role : '',
    companyName: userState.companyName ? userState.companyName : '',
    companySize: userState.companySize ? userState.companySize : '',
  };

  const handleSubmit = async(values: FormValues, actions: any) => {
    try {
      // @ts-expect-error not sure about this one
      dispatch(setupUserProfile({
        first_name: values.firstName,
        last_name: values.lastName,
        company_name: values.companyName,
        company_size: values.companySize,
        role: values.role,
      }));
    } finally {
      actions.setSubmitting(false);
      AnalyticsManager.trackEvent(TapContinueProfile);
    }
  };

  const handleChangeRole = (selectedValue: any) => {
    AnalyticsManager.trackEvent(TapChooseRole, { role: selectedValue.label });
  };

  const handleChangeSize = (selectedValue: any) => {
    AnalyticsManager.trackEvent(EnterCompanySize, { company_size: selectedValue.label });
  };

  const handleValueChange = (e: any) => {
    e.target.name === 'companyName' && AnalyticsManager.trackEvent(EnterCompanyName);
    e.target.name === 'firstName' && AnalyticsManager.trackEvent(EnterFirstName);
    e.target.name === 'lastName' && AnalyticsManager.trackEvent(EnterLastName);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userProfileSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          <div className="mb-4">
            <Input
              onBlur={handleValueChange}
              className="p-2"
              id="firstName"
              placeholder="First name"
              name='firstName'
              type="text"
            />
          </div>
          <div className="mb-4">
            <Input
              onBlur={handleValueChange}
              id="lastName"
              placeholder="Last name"
              name="lastName"
              type="text"
            />
          </div>
          <div className="mb-4">
            <DropdownInput
              onChange={handleChangeRole}
              values={roles}
              placeholder="Your role"
              id="role"
              name="role"
            />
          </div>
          <div className="mb-4">
            <Input
              onBlur={handleValueChange}
              id="companyName"
              name="companyName"
              placeholder="Company name"
              type="text"
            />
          </div>
          <div className="mb-4">
            <DropdownInput
              onChange={handleChangeSize}
              values={companySizes}
              placeholder="Company size"
              id={"companySize"}
              name="companySize"
              menuPlacement="top"
            />
          </div>
          <B2BButton
            disable={!isValid || !dirty}
            expand={true}
            label={"Continue"}
            isLoading={isSubmitting}
            theme="dark"
            type="submit"
          />
        </Form>
      )}
    </Formik>

  );
};

export default SetupProfileForm;
