import { B2BButton } from "../b2bbutton/b2bbutton";
import 'primeicons/primeicons.css';
import { useNavigate } from "react-router-dom";
import { useCreateQuestionMutation, useCreateSurveyMutation } from "../../redux/survey/survey.endpoints";
import plusIcon from '../../assets/icons/Plus-white.svg';
import { useEffect } from "react";
import SurveyService from "../../services/surveys/survey.service";
import FirebaseService from "../../services/firebase/firebase.service";
import { SurveySampleSizes } from "../../services/firebase/firebase.types";
import { SURVEY_MINIMUM_TARGET_SIZE } from "../../services/surveys/survey.constants";
import AnalyticsManager from '../../services/analytics/analytics.service';
import { AnalyticEventsEnum } from '../../services/analytics/analytic-event-enum';
import {useDispatch} from "react-redux";
import {setSurveyTargetingToggle} from "../../redux/survey/survey-targeting-toggle.slice";

interface DashboardButtonProps {
  readonly labelText?: string;
}

export const DashboardButton = ({ labelText = 'Create Survey' }: DashboardButtonProps) => {
  const config = FirebaseService.getRemoteConfigJSON<SurveySampleSizes>('SAMPLE_SIZES');
  const targetingConfig = FirebaseService.getRemoteConfigBool('SURVEYS_DEFAULT_TARGETED');
  const nonZeroHumanSampleSize = config?.human.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const nonZeroAI = config?.ai.find((size) => size > 0) ?? SURVEY_MINIMUM_TARGET_SIZE;
  const [createSurvey, { data: createdSurvey, error: surveyError }] = useCreateSurveyMutation();
  const [createQuestion, { data: createdQuestion, error: questionError }] = useCreateQuestionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    surveyError && console.error('Error creating survey:', surveyError);
    questionError && console.error('Error creating question:', questionError);
  }, [surveyError, questionError]);

  useEffect(() => {
    if (!createdSurvey) {
      return;
    }

    void createQuestion(SurveyService.createQuestionData(createdSurvey.extSurveyId, 0));
    dispatch(setSurveyTargetingToggle({ surveyId: createdSurvey.extSurveyId, isTargeted: targetingConfig }));
  }, [createdSurvey]);

  useEffect(() => {
    createdSurvey && createdQuestion && navigate(`/create-survey?ext-survey-id=${createdSurvey.extSurveyId}`);
  }, [createdQuestion, createdSurvey]);

  const handleCreateSurvey = () => {
    AnalyticsManager.trackEvent(AnalyticEventsEnum.CreateSurvey);
    void createSurvey({ title: "Untitled", human_sample_size: nonZeroHumanSampleSize, ai_sample_size: nonZeroAI });
  };

  return (
    <B2BButton
      icon={plusIcon}
      label={labelText}
      theme='dark'
      size='small'
      onClick={handleCreateSurvey}
    />
  );
};
