import { ReactElement } from 'react';
import { Dialog } from "primereact/dialog";
import PhoneOutline from "./components/phone-outline";
import SurveyPreview from "./components/survey-preview";
import { Card } from "primereact/card";
import './survey-preview-modal.css';
import { Question, SurveyFull } from "../../redux/survey/survey.types";
import plusBlack from "../../assets/icons/Plus-black.svg";
import { useSurveyPreview } from "./useSurveyPreview";
import { partial } from "lodash";
import { Button } from "primereact/button";
import backArrow from "../../assets/icons/back-arrow.svg";
import { useGetSurveyAudienceQuery } from "../../redux/survey/survey.endpoints";
import { convertToAttributes } from "../criteria/criteria-settings";

export interface PreviewSurveyModalProps {
  readonly onHide: () => void;
  readonly isVisible: boolean;
  readonly questions: Question[];
  readonly survey: SurveyFull;
}

const SurveyPreviewModal = ({ onHide, isVisible, survey }: PreviewSurveyModalProps): ReactElement => {
  const { innerWidth } = window;
  const surveyPreviewProps = useSurveyPreview(survey.questions);
  const audience = survey.audience || '';
  const { data: audienceData } = useGetSurveyAudienceQuery({ id: audience }, { skip: !audience });
  const { activeQuestionIndex, setActiveQuestionIndex, onPressBack, onPressNext } = surveyPreviewProps;

  function renderTargetAudience(): ReactElement | null {
    if (!audienceData) {
      return null;
    }

    const attributes = convertToAttributes(audienceData.criteria);
    const mapped = attributes.map(({ label, options }) => {
      return { label, options: options.map(({ value }) => value).join(', ') };
    });
    if (!mapped.length) {
      return null;
    }

    return (
      <div className="flex flex-col grow justify-end text-sm">
        <div className="text-status-gray">
          Target audience
        </div>
        {mapped.map(({ label, options }) => (
          <div
            key={label}
            className="flex flex-row gap-2 mt-1"
          >
            <span className="text-status-gray">
              {label}
            </span>
            <span>
              {options}
            </span>
          </div>
        ))}
      </div>
    );
  }

  function renderQuestionItem(title: string, index: number): ReactElement {
    const activeQuestionStyles = index === activeQuestionIndex ? 'border-2 border-blue-500' : '';

    return (
      <li key={`card-list-${index}`}>
        <Card
          className={`${activeQuestionStyles} flex p-4 rounded-xl shadow cursor-pointer`}
          onClick={partial(setActiveQuestionIndex, index)}
        >
          <div className='flex flex-row gap-4'>
            <span className='text-black opacity-50'>
              {index + 1}
            </span>
            <span className='line-clamp-1'>
              {title}
            </span>
          </div>
        </Card>
      </li>
    );
  }

  function renderQuestionList(): ReactElement {
    return (
      <div className='flex flex-1 rounded-s-xl bg-gray-100'>
        <ul className='flex flex-col gap-2 max-h-full overflow-y-scroll p-8'>
          <div className="text-sm text-status-gray mb-6">
            Survey preview
          </div>
          <div className="text-2xl mb-6">
            {survey.title}
          </div>
          {survey.questions.map(({ title }, index) => renderQuestionItem(title, index))}
          {audienceData && renderTargetAudience()}
        </ul>
      </div>
    );
  }

  function renderClose(): ReactElement {
    const position = innerWidth > 640 ? 'top-4 right-4' : 'top-3 right-3';

    return (
      <div className={`${position} absolute z-10`}>
        <Button
          onClick={onHide}
          className='flex justify-center items-center bg-gray-300 size-8 rounded-full'
        >
          <img
            src={plusBlack}
            alt='close'
            className='rotate-45'
            draggable={false}
          />
        </Button>
      </div>
    );
  }

  function renderBackButton(): ReactElement {
    return (
      <Button
        className='flex size-8 items-center justify-center bg-blue-500 rounded-full p-1'
        onClick={onPressBack}
      >
        <div className='relative -start-[1px] invert w-2 h-3'>
          <img
            src={backArrow}
            alt="previous question"
            draggable={false}
          />
        </div>
      </Button>
    );
  }

  function renderNextButton(): ReactElement {
    return (
      <Button
        className='bg-blue-500 flex size-8 items-center justify-center rounded-full p-1'
        onClick={onPressNext}
      >
        <div className='-end-[1px] relative invert rotate-180 -scale-y-100 w-2 h-3'>
          <img
            src={backArrow}
            alt="next question"
            draggable={false}
          />
        </div>
      </Button>
    );
  }

  function renderPhone(): ReactElement {
    return (
      <PhoneOutline>
        <SurveyPreview
          questions={survey.questions}
          surveyPreviewProps={surveyPreviewProps}
        />
      </PhoneOutline>
    );
  }

  function renderSmallModal(): ReactElement {
    return (
      <div className='flex h-full w-full justify-around gap-3 items-center'>
        {renderClose()}
        {renderBackButton()}
        {renderPhone()}
        {renderNextButton()}
      </div>
    );
  }

  function renderFullModal(): ReactElement {
    return (
      <div className='flex h-full'>
        {renderQuestionList()}
        <div className='flex flex-2 justify-center items-center gap-6'>
          {renderBackButton()}
          {renderPhone()}
          {renderNextButton()}
        </div>
        {renderClose()}
      </div>
    );
  }

  function renderDialogContent(): ReactElement {
    if (innerWidth < 640) {
      return renderSmallModal();
    }

    return renderFullModal();
  }

  return (
    <Dialog
      onHide={onHide}
      visible={isVisible}
      draggable={false}
      blockScroll
      className={innerWidth > 640 ? 'size-11/12 rounded-xl' : `p-3 max-h-[98%]`}
      content={renderDialogContent}
    />
  );
};

export default SurveyPreviewModal;
