import {en} from "../locales/en";
import {DashboardMenubar} from "../components/menubar/menubar";
import {Button} from "primereact/button";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import ErrorLogService from "../services/error-log/error-log.service";
import {useSetPageTitle} from "../hooks/useSetPageTitle";

const { title, button } = en.four04;

const Four04 = () => {
  useSetPageTitle('Page not found');
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({'404': 't'});
    ErrorLogService.logError(new Error('404 page not found'), '404.tsx', 'mount', [location]);
  }, []);

  function handleBackToDashboard() {
    navigate('/dashboard');
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <DashboardMenubar />
      <div className="flex flex-col gap-16 mx-8 sm:mx-20 my-14 text-center sm:text-left">
        <h1 className="font-light text-3xl sm:text-5xl">
          {title}
        </h1>
        <div>
          <Button
            className={'py-3 px-8 sm:px-16 bg-black text-white rounded-full'}
            label={button}
            onClick={handleBackToDashboard}
            size={'large'}
          />
        </div>
      </div>
    </div>
  );
};

export default Four04;
