import React from 'react';

interface AnswerResultBarProps {
  answerLabel: string;
  hasImageAnswers?: boolean;
  imgSrc?: string | null;
  topAnswer: boolean;
  totalCount: number;
  userPercent: number;
}

const AnswerResultBar: React.FC<AnswerResultBarProps> = ({
  answerLabel,
  hasImageAnswers,
  imgSrc,
  topAnswer,
  totalCount,
  userPercent,
}) => {
  const percentage = parseFloat((userPercent ?? 0).toFixed(2));

  const containerClasses = `${hasImageAnswers ? 'h-20' : 'h-14'} w-full rounded-2xl relative overflow-hidden transition-all ${
    topAnswer ? 'bg-custom-blue-1' : 'bg-gray-200'
  }`;

  const barClasses = `transition-all ${percentage >= 98 ? 'rounded-2xl' : 'rounded-l-2xl'}
    ${topAnswer ? 'bg-custom-blue-2' : 'bg-gray-300'}
    ${hasImageAnswers ? 'h-20' : 'h-14'}`
  ;

  const textClasses = `absolute inset-0 flex items-center justify-center 
    ${topAnswer ? 'text-white' : 'text-black'}
  `;

  const accessoryClasses = `absolute inset-0 flex items-center px-2
    ${imgSrc ? 'justify-between' : 'justify-end'}
  `;

  const percentageClasses = `${
    topAnswer ? 'text-white  rounded-full bg-white/[.1] px-2' : 'text-custom-blue-1 border rounded-full bg-custom-blue-gray px-2'
  }`;

  return (
    <div className="w-full rounded-lg py-2">
      <div className={containerClasses}>
        <div
          className={barClasses}
          style={{ width: `${percentage}%` }}
        />
        <span className={textClasses}>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap w-3/5 micro:w-1/5 text-center">
            {answerLabel}
          </span>
        </span>
        <div className={accessoryClasses}>
          {imgSrc && (
            <img
              className="size-16 rounded-lg"
              src={imgSrc}
              alt=""
            />
          )}
          <div className={percentageClasses}>
            {percentage}
            % (
            {totalCount}
            )
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerResultBar;
